import { observable, toJS } from 'mobx'
import moment from 'moment'
import { CheckCircle, User, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import modalConfirm from '../components/Confirm'
import { ROLE_RESEAU, ROLE_COMMERCANT } from '../utils/constants'
import myYup from '../utils/myYup'
import LoadingStore from './LoadingStore'
import ReseauStore from './ReseauStore'
import UserStore from './UserStore'


const props = {
    pageReady: false,
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    currentArticles: {},
    data: [],
    steps: [],
    pagination: {},
    isOpenDetailModal: false,
    isOpenEditCreateModal: false,
    isOpenResultModal: false,
    isOpenPreviewModal: false,
    shops_with_no_modele: [],
    currStep: 0,
    predifinedModeles: [],
    listShop: [],
    currJson: null,
    currContenu: null,
    currentResultRow: {},
    currentSurveyRow: {},
    currentPreview: {},
    listActions: []
}

const SurveyStore = observable({
    /***** Props ******/
    props: props,
    /***** Filter ******/
    getFilteredData(values) {
        // console.log(toJS(values))
        this.props.activeFilters = values
        this.loadData()
    },
    /***** Sort ******/
    toggleSort(column) {
        // console.log(column)
        for (let key in this.props.activeFilters) {

            if (column == key) {

                if (!this.props.activeFilters[key].sort) {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'ASC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'ASC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'DESC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'DESC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = null
                    // break
                }
            }
            else {
                this.props.activeFilters[key].sort = null
            }

        }

        this.loadData()
        console.log(toJS(this.props.filters))
    },
    /***** Toggle Columns ******/
    toggleColumn(column) {
        console.log('toggle')
        this.props.columns.map((item, index) => {
            if (item.key == column.key) {
                this.props.columns[index].isHidden = !this.props.columns[index].isHidden
                console.log(this.props.columns[index].isHidden)

            }
        })
    },
    /***** Pagination change ******/
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.loadData()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.loadData()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.loadData()
                break;

            default:
                break;
        }

    },
    /***** Initialisation ******/
    async init() {

        //On reset le store
        this.reset()

        //Initialisation des listes
        // this.props.listShop = await this.initListShop()

        // Initialisation des columns 
        this.props.columns = [
            {
                label: 'Actif',
                key: 'commercants',
                field: 'commercants',
                render: (value, row) => {
                    if (row.is_default)
                        return <CheckCircle size={20} className="text-success"></CheckCircle>
                    else if (value.length > 0)
                        return <CheckCircle size={20} className="text-success"></CheckCircle>
                    else
                        return <XCircle size={20} className="text-danger"></XCircle>
                }
            },
            // {
            //     key: 'is_active',
            //     label: 'Actif',
            //     field: 'is_active',
            //     render: (value) =>
            //         value == 1 ?
            //             <CheckCircle size={20} className="text-success"></CheckCircle> :
            //             <XCircle size={20} className="text-danger"></XCircle>
            // },
            {
                key: 'nom',
                label: 'Nom survey',
                field: 'nom',
            },
            {
                label: 'Réseau',
                key: 'reseau',
                field: 'reseau',
                render: (value) => {
                    return value?.nom

                }
            },
            {
                label: 'Actif pour',
                key: 'commercants',
                field: 'commercants',
                render: (value, row) => {
                    return row.is_default ? 'Tous les magasins' : value.map(item => item?.nom + ', ')

                }
            },
            {
                key: 'created_at',
                label: 'Créée le',
                field: 'created_at',
                render: (value) => moment(value).format('DD/MM/YYYY')
            },

            {
                key: 'actions',
                label: '',
                listActions: [
                    {
                        label: 'Activer/Désactiver cette enquête',
                        name: 'toggle',
                        action: (row) => this.toggleActivateModal(row)
                    },
                    {
                        label: 'Editer',
                        name: 'edit',
                        action: (row) => SurveyStore.toggleEditCreateModal(row)
                    },
                    {
                        label: 'Voir les résultats',
                        name: 'result',
                        action: (row) => SurveyStore.toggleResultModal(row)
                    },
                    {
                        label: 'Supprimer',
                        name: 'delete',
                        action: (row) => this.deleteSurvey(row)
                    }
                ]
            }
        ]

        // Initialisation des filtres
        this.props.filters = {
            nom: {
                label: 'Nom survey',
                isSortable: true,
                active: false,
                type: 'text',
                operator: '%%',
                value: null,
                sort: null
            },
            created_at: {
                label: 'Date création',
                isSortable: true,
                active: false,
                type: 'date',
                operator: 'between',
                value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                sort: 'DESC'
            }
        }

        //Initialisation pagination
        this.props.pagination = {
            index: 1,
            offset: 20,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

        //Inititialisation des étapes de création d'un survey
        this.props.steps = [
            // {
            //     title: 'Choix magasins',
            //     hide: [],
            //     validationSchema: myYup.object().shape({
            //         shops: myYup.array().min(1, 'Vous devez sélectionner au moins un magasin'),
            //     })
            // },
            {
                title: 'Choix modèle',
                hide: ['edit'],
                // validationSchema: myYup.object().shape({
                //     modele_id: myYup.number().required()
                // })
            },
            {
                title: 'Conception',
                hide: [],
                validationSchema: myYup.object().shape({
                    nom: myYup.string().required(),
                    // objet: myYup.string().required(),
                    is_active: myYup.string(),
                    shops: myYup.array().when('is_active', {
                        is: (val) => val == 1,
                        then: myYup.array().when('reseau.default_survey_id', {
                            is: (val) => val == 0,
                            then: myYup.array().min(1, 'Vous devez sélectionner au moins un magasin'),
                            otherwise: null
                        })
                        ,
                        otherwise: null,
                    }),
                    json: myYup.string().required(),

                })
            }
        ]

        this.props.activeFilters = this.props.filters
        await this.loadData()
        this.props.pageReady = true
    },
    /***** Methods ******/
    async initListShop() {
        const res = await api.post('/commercant/listShop')
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.data.map((item => ({ id: item.id, label: item.nom })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return []
        }
    },
    async fetchShops(reseau = null) {
        const res = await api.post('/commercant/listShopByReseau', { reseau: reseau })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            this.props.listShop = res.data.data.map((item => ({ id: item.id, label: item.nom })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return []
        }
    },
    async checkIfAlreadySet(values) {
        const res = await api.post('/survey/checkIfAlreadySet', values)
        if (res.data && res.data.success) {
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return null
        }
    },
    async deleteSurvey(values) {
        LoadingStore.on()

        let next = false


        let text = '<p>Etes-vous sûr(e) de vouloir supprimer l\'enquête suivante :</p>';
        text += '<p><b>' + values.nom + '</b></p>';

        next = await modalConfirm({
            title: 'Confirmation',
            text: text
        })


        if (next) {
            const res = await api.post('/survey/deleteSurvey', values)
            if (res.data && res.data.success) {
                // this.toggleEditCreateModal()
                modalAlert({
                    title: 'Succes',
                    typeAlert: 'success',
                    text: res.data.msg
                })
                await this.loadData()
            }
            // else if (res.data.errors) {
            //     setErrors(res.data.errors)
            // }
            else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
            }
        }
        LoadingStore.off()
    },
    async toggleEditCreateModal(row) {
        console.log(row)

        this.props.currentRow = row ? row : {}
        if (!row && !this.props.isOpenEditCreateModal) {
            const ress = await this.fetchModeleSurvey()
            this.props.predifinedModeles = ress.data

            //Si on est en role RESEAU ou COMMERCANT alors on rapatrie les information du réseau
            if (UserStore.props.user.role_id == ROLE_RESEAU || UserStore.props.user.role_id == ROLE_COMMERCANT) {
                const currReseau = await ReseauStore.fetchCurrReseauParams()
                this.props.currentRow.reseau = currReseau

            }

            // Si on est en role commercant alors on mets les infos courante dans le chaps shops
            if (UserStore.props.user.role_id == ROLE_COMMERCANT) {
                this.props.currentRow.commercants = [UserStore.props.user.commercant]

            }
        }
        else if (row && !this.props.isOpenEditCreateModal) {

            const res = await this.fetchModeleSurveyById(row.id)
            console.log(res)
            if (res && res.success) {
                this.props.currentRow.contenu = res.data.contenu
                this.props.currentRow.json = res.data.json
                this.props.currentRow.commercants = res.data.commercants.map(item => item.id)
                this.props.currentRow.reseau = res.data.reseau
            }
        }
        this.props.isOpenEditCreateModal = !this.props.isOpenEditCreateModal
        if (!this.props.isOpenEditCreateModal)
            setTimeout(() => this.props.currStep = 0, .5)

    },
    async toggleActivateModal(row) {
        console.log(row)
        this.props.currentRow = row ? row : {}
        if (row) {
            const res = await this.fetchModeleSurveyById(row.id)
            if (res)
                this.props.currentRow.reseau = res.data.reseau
            this.props.currentRow.commercants = row.commercants.map(item => item.id)
        }
        this.props.isOpenActivateModal = !this.props.isOpenActivateModal
    },
    async toggleResultModal(row) {
        console.log(row)

        this.props.currentResultRow = row ? row : {}

        if (row && !this.props.isOpenResultModal) {

            const res = await this.fetchSurveyResultById(row.id)
            console.log(res)
            if (res && res.success) {
                this.props.currentResultRow = res.data.result
                this.props.currentSurveyRow = res.data.survey
            }
        }
        this.props.isOpenResultModal = !this.props.isOpenResultModal

    },
    async fetchModeleSurveyById(modele_id) {
        LoadingStore.on()
        const res = await api.post('/survey/getModeleSurveyById', { modele_id: modele_id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async fetchSurveyResultById(id) {
        LoadingStore.on()
        const res = await api.post('/survey/getSurveyResultById', { id: id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async fetchModeleSurvey(row) {
        LoadingStore.on()
        const res = await api.post('/survey/getModeleSurvey')
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async loadData() {
        LoadingStore.on()
        const res = await api.post('/survey/list', { pagination: this.props.pagination, filters: this.props.activeFilters })
        if (res.data && res.data.success) {
            this.props.data = res.data.data
            this.props.pagination.total = res.data.total
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    async submitSurvey(values) {
        LoadingStore.on()

        let next = true

        const already = await this.checkIfAlreadySet(values)

        if (already.alert){

            let text = '<p>En sauvgardant  ce modèle, vous remplacerez les modèles actifs des magasins suivants :</p><p><ul>';
            already.commercantsToAlert.map(item => text += '<li><b>' + item.nom + '</b></li>');
            text += '</ul></p><p>Etes-vous sûr(e) de vouloir continuer ? </p>'

            next = await modalConfirm({
                title: 'enregistrement',
                text: text
            })

        }
        if (next) {
            const res = await api.post('/survey/store', values)
            if (res.data && res.data.success) {
                this.toggleEditCreateModal()
                modalAlert({
                    title: 'Succes',
                    typeAlert: 'success',
                    text: res.data.msg
                })
                await this.loadData()
            }
            // else if (res.data.errors) {
            //     setErrors(res.data.errors)
            // }
            else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
            }
        }
        LoadingStore.off()
    },
    async toggleModeleSurvey(values, setErrors) {
        LoadingStore.on()
        const res = await api.post('/survey/toggleModeleSurvey', values)
        if (res.data && res.data.success) {
            console.log(res.data)
            this.toggleActivateModal()
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
            await this.loadData()
        }
        else if (res.data.errors) {
            setErrors(res.data.errors)
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    getValidationSchema() {
        const steps = this.getWizardSteps()
        console.log(steps, toJS(this.props.currStep))
        return steps[this.props.currStep].validationSchema
    },
    getWizardSteps() {
        if (this.props.currentRow.json)
            return this.props.steps.filter(item => !item.hide.includes('edit'))
        else
            return this.props.steps
    },
    async nextStep(data = {}, setFieldValue) {

        if (this.props.currStep == 1) {
            const { modele_id } = data

            if (modele_id) {
                const res = await this.fetchModeleSurveyById(modele_id)
                // console.log('test', res.data)
                setFieldValue('contenu', res.data.contenu)
                setFieldValue('json', res.data.json)

            } else {
                setFieldValue('contenu', '')
                setFieldValue('json', null)
            }
        }

        // if (this.props.currStep == 1) {
        //     console.log('In enxt',setDesign)

        // }
        this.props.currStep++
    },
    async prevStep(data = {}) {
        if (typeof this.props.steps[this.props.currStep].onNextStep == 'function')
            this.props.steps[this.props.currStep].onPrevStep(data)
        this.props.currStep--;
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default SurveyStore