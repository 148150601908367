import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
import DatatableSimple from '../../components/DatatableSimple';
import Input from '../../components/Input';
import EditeurStore from '../../stores/EditeurStore';
import ReseauStore from '../../stores/ReseauStore'
import UserStore from '../../stores/UserStore';
import { ROLE_ADMIN } from '../../utils/constants';
import myYup from '../../utils/myYup'

export const InfosModal = observer((props) => {

    const { isOpen, toggle, row } = props


    return (
        <Modal isOpen={isOpen} centered={true} size={'lg'}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    <h4>Infos API</h4>
                    <div className="row">
                        <div className="col-12">
                            <h5>Id commerçant</h5>
                            <div className="w-100  d-flex jc">
                                <span className="font-weight-bold text-orange" style={{ fontSize: '1.5rem' }}>{row.id}</span>

                            </div>
                        </div>
                        <div className="col-12">
                            <h5>Api Key</h5>
                            <div className="w-100  d-flex jc">
                                <span className="font-weight-bold text-orange" style={{ fontSize: '1.5rem' }}>{row.api_key}</span>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            }
        </Modal >
    )
});

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row, fetchEditeurs, role } = props

    const { errors, setFieldValue, handleSubmit, values, setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values, setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Reseau</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Reseau</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom enseigne</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Clé de licence</label>
                            <Input
                                name="licence_key"
                                type="text"
                                value={values['licence_key']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['licence_key']}
                            />
                        </div>
                        {
                            role == ROLE_ADMIN &&
                            <div className="form-group col-md-12 col-12">
                                <label>Editeur associé</label>
                                <Input
                                    name="editeur"
                                    type="mySelect"
                                    async={true}
                                    placeholder={"-- Choix éditeur --"}
                                    fetch={fetchEditeurs}
                                    value={values['editeur']}
                                    className={"form-control"}
                                    onChange={setFieldValue}
                                    error={errors['editeur']}
                                />
                            </div>
                        }

                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                            </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const ReseauPage = observer((props) => {

    const pageReady = ReseauStore.props.pageReady

    useEffect(() => {
        ReseauStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={ReseauStore.props.columns}
                                    listActions={ReseauStore.props.listActions}
                                    data={ReseauStore.props.data}
                                    pagination={ReseauStore.props.pagination}
                                    onPaginationChange={(action, index) => ReseauStore.onPaginationChange(action, index)}
                                    filters={ReseauStore.props.filters}
                                    applyFilter={(values) => ReseauStore.getFilteredData(values)}
                                    toggleEdit={() => ReseauStore.toggleEditCreateModal()}
                                    toggleSort={(column) => ReseauStore.toggleSort(column)}
                                    toggleColumn={(column) => ReseauStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <EditCreateModal
                                    role={UserStore.props.user.role_id}
                                    isOpen={ReseauStore.props.isOpenEditCreateModal}
                                    toggle={() => ReseauStore.toggleEditCreateModal()}
                                    row={ReseauStore.props.currentRow}
                                    fetchEditeurs={(search) => EditeurStore.fetchEditeurs(search)}
                                    submit={(values, setErrors) => ReseauStore.submitReseau(values, setErrors)}
                                ></EditCreateModal>
                                {/* <InfosModal
                                    isOpen={ReseauStore.props.isOpenInfosModal}
                                    toggle={() => ReseauStore.toggleInfosModal()}
                                    row={ReseauStore.props.currentInfosRow}
                                ></InfosModal> */}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default ReseauPage