import React from 'react'
import { useFormik } from 'formik'
import { Lock, Mail } from 'react-feather'
import Input from '../../components/Input'
import UserStore from '../../stores/UserStore'
import { Link, useHistory } from "react-router-dom";
import { withRouter } from 'react-router';
import Logo from '../../components/Logo'
import { observer } from 'mobx-react';

const LoginPage = observer((props) => {
    // let history = useHistory();

    let history = useHistory();

    const redirectIfLoginSuccess = () => history.push({
        pathname: '/dashboard',
        state: { name: 'Tableau de bord' }

    })

    const { errors, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            email: null,
            password: null,
        },
        onSubmit: values => UserStore.login(values, redirectIfLoginSuccess),
        validateOnBlur: false,
        validateOnChange: false
    })

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <Logo className="dematick-orange-grey"></Logo>
                </div>
                <div className="card">
                    <form className="card-body login-card-body">
                        <div className="input-group mb-3">
                            <Input
                                name="email"
                                type="email"
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['email']}
                            />

                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <Mail></Mail>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <Input
                                name="password"
                                type="password"
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['password']}
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <Lock></Lock>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={handleSubmit} className="btn btn-primary btn-block">Se connecter</button>
                            </div>
                        </div>
                        <p className="mb-1 mt-2">
                            <Link>Mot de passe oublié?</Link>
                        </p>
                    </form>
                </div>
            </div>

        </div>
    )
})

export default withRouter(LoginPage)