import React, { useEffect, useRef, useState } from 'react'
import * as Survey from "survey-react/survey.react.min.js";
import * as SurveyCreator from "survey-creator/survey-creator.min.js";
import * as SurveyAnalytics from "survey-analytics/survey.analytics.min.js";
import "survey-creator/survey-creator.min.css";
import "survey-react/modern.min.css";
import "survey-analytics/survey.analytics.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";

Survey.StylesManager.applyTheme("modern");
SurveyCreator.StylesManager.applyTheme("modern");
Survey.surveyLocalization.defaultLocale = "fr";
const myloc = Survey.surveyLocalization.getCurrentStrings();
console.log(SurveyCreator.editorLocalization)
myloc.completingSurvey = "Merci d’avoir répondu à notre enquête !"

const mycustomSurveyStrings = {
    pagePrevText: "Précédent",
    pageNextText: "Suivant",
    completeText: "Terminer"
};
Survey
    .surveyLocalization
    .locales["fr"] = mycustomSurveyStrings;


Survey.Serializer.findProperty("survey", "title").visible = true;
Survey.Serializer.findProperty("survey", "title").value = 'TEST';
Survey.Serializer.findProperty("survey", "description").visible = false;
Survey.Serializer.findProperty("survey", "showTitle").visible = false;

export const SurveyBuilder = (props) => {

    const { json, save } = props
    const ref = useRef(null)

    useEffect(() => {

        let creatorOptions = {
            questionTypes: ["text", "checkbox", "radiogroup", "dropdown", "rating", "comment"]
        };
        Survey.surveyLocalization.defaultLocale = "fr";
        SurveyCreator.localization.currentLocale = "fr";
        ref.current = new SurveyCreator.SurveyCreator(null, creatorOptions);
        console.log(ref.current)
        ref.current.showToolbox = "left";
        ref.current.showJSONEditorTab = false
        ref.current.showPropertyGrid = "left";
        ref.current.rightContainerActiveItem("toolbox");
        ref.current.showSurveyTitle = 'always'
        ref.current.allowControlSurveyTitleVisibility = false
        ref.current.isAutoSave = false
        ref.current.completedHtml  = "JOEL"

        console.log(ref.current)

        if (json)
            ref.current.JSON = JSON.parse(json)

        //Add all countries question into toolbox
        // creator.toolbox.addItem({
        //     name: "countries",
        //     isCopied: true,
        //     iconName: "icon-default",
        //     title: "All countries",
        //     json: { "type": "dropdown", optionsCaption: "Select a country...", choicesByUrl: { url: "https://surveyjs.io/api/CountriesExample" } }
        // });

        ref.current.render("surveyCreatorContainer");

        console.log(ref.current)

    }, [])

    let saveSurvey = () => {
        if (save)
            save(JSON.stringify(ref.current.JSON));

            console.log(ref.current)
    }


    return (
        <>
            <div id="surveyCreatorContainer" />
            <div className="row">
                <div className="form-group col-12">
                    <button onClick={() => saveSurvey()} className="btn btn-primary">
                        Valider
                    </button>
                </div>
            </div>
        </>
    )


}

export const SurveyPreview = (props) => {

    const {
        json
    } = props

    const frame_mobile = useRef(null)
    const frame_desktop = useRef(null)


    const [isMobile, setIsMobile] = useState(false)

    console.log(json)
    const survey = new Survey.Model(json);


    return (
        <>
            <h5 className="text-center">Aperçu</h5>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center mb-2">
                        <div className="px-3">
                            <FontAwesomeIcon icon={faDesktop} className="text-old" size="lg"></FontAwesomeIcon>
                        </div>
                        <div >
                            <Switch
                                checked={isMobile}
                                onChange={() => setIsMobile(!isMobile)}
                                onColor="#fd7e13"
                                onHandleColor="#ebedef"
                                offColor="#fd7e13"
                                offHandleColor="#ebedef"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={16}
                                width={30}
                                className="react-switch"
                            />
                        </div>
                        <div className="px-3">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-old" size="lg"></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
            </div>
            {
                isMobile &&
                <div className="row jc">
                    <div className="mobile-content">
                        <div className="mobile-preview-samsung">
                            <div class="mobile-wrapper">
                                <div style={{ height: '100%' }}>
                                    {
                                        !json &&
                                        <div className="no-preview h2 text-center">
                                            Aucun contenu
                                        </div>
                                    }
                                    {
                                        json && json.length > 0 &&
                                        <Survey.Survey
                                            model={survey}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                !isMobile &&
                <div className="d-flex jc">
                    <div className="row col-md-12">
                        <div className="desktop-header rounded-top col-12"></div>
                        <div class="desktop-wrapper col-12 rounded-bottom">
                            <div style={{ height: '100%' }}>
                                {
                                    !json &&
                                    <div className="no-preview h2 text-center">
                                        Aucun contenu
                                    </div>
                                }
                                {
                                    json && json.length > 0 &&
                                    <Survey.Survey
                                        model={survey}
                                    />
                                }

                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export const SurveyResult = (props) => {

    const { currentSurveyRow, currentResultRow } = props

    let creator

    useEffect(() => {
        let survey = JSON.parse(currentSurveyRow)
        let result = currentResultRow

        let model = new Survey.Model(survey);
        console.log(survey)
        model.locale = "fr"




        var normalizedData = result.map(function (item) {
            let temp = JSON.parse(item)
            model.getAllQuestions().forEach(function (q) {
                console.log(q)
                if (!temp[q.name]) {
                    temp[q.name] = "";
                }
            });
            return temp;
        });

        console.log(normalizedData)

        creator = new SurveyAnalytics.VisualizationPanel(
            model.getAllQuestions(),
            normalizedData,
            { labelTruncateLength: 27 }
        );
        var surveyResultNode = document.getElementById("surveyResultContainer");
        creator.showHeader = true;
        creator.render(surveyResultNode);
        // creator.render("surveyResultContainer");


        // console.log(creator)

    }, [])


    return (
        <>
            <div id="surveyResultContainer" />
            <div className="row">
                <div className="form-group col-12">

                </div>
            </div>
        </>
    )


}
