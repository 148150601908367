export const ROLE_SUPER_ADMIN = 1
export const ROLE_ADMIN = 2
export const ROLE_EDITEUR = 3
export const ROLE_RESEAU = 4
export const ROLE_COMMERCANT = 5

export const roleLabel = {
    1: 'Super Admin',
    2: 'Administrateur',
    3: 'Editeur',
    4: 'Gestionnaire d\'enseigne',
    5: 'Commerçant',
}