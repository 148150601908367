import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
// import Datatable from '../../components/Datatable'
import DatatableSimple from '../../components/DatatableSimple'
import TicketStore from '../../stores/TicketStore'
import { numberFormat } from '../../utils/utils';

export const DetailModal = observer((props) => {

    const { isOpen, toggle, row, currentTicket } = props


    // console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>

                    <h4>Détail Ticket</h4>
                    {
                        !!row.json &&
                        <div className="ticket-json">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }}>
                                            <img src={currentTicket.json.logo} style={{ width: '250px' }}></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                            {currentTicket.json.nom}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                            {currentTicket.json.address_1}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                            {currentTicket.json.address_2}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                            {currentTicket.json.postal_code} - {currentTicket.json.city}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '0px 0px' }}>
                                            {currentTicket.json.phone}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '5px 0px' }}>
                                            {currentTicket.json.receipt_date}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                            Ticket n° {currentTicket.json.receipt_number}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                            {currentTicket.json.header}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%', margin: 0 }}>
                                <thead>
                                    <th></th>
                                    <th style={{ textAlign: 'right' }}>TTC(€)</th>
                                    <th style={{ textAlign: 'right' }}>TVA(%)</th>
                                </thead>
                                <tbody>
                                    {!!currentTicket.json?.ticket?.categories &&
                                        Object.keys(currentTicket.json.ticket.categories).map(key =>
                                            <>
                                                <tr>
                                                    <td style={{ fontWeight: 800, padding: '8px 0px 0px 0px' }}>{key}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                {
                                                    currentTicket.json.ticket.categories[key].map(item =>
                                                        <tr>
                                                            <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                            <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                            {
                                                                !!item.tva_applied &&
                                                                <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    {
                                        !!currentTicket.json?.ticket?.items &&
                                        currentTicket.json.ticket.items.map(item =>
                                            <tr>
                                                <td style={{ padding: '3px 0px 3px 7px' }}>{item.name}</td>
                                                <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.total_price)}</td>
                                                {
                                                    !!item.tva_applied &&
                                                    <td style={{ padding: '3px 0px 3px 0px', textAlign: 'right' }}>{numberFormat(item.tva_applied)}</td>
                                                }
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td colspan="2" style={{ borderTop: '1px dashed #000000' }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: '5px 0px 5px 0px' }}></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600 }}>TOTAL HT (€)</td>
                                        <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_ht)}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600 }}>TOTAL TVA (€)</td>
                                        <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_tva)}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 600 }}>TOTAL (€)</td>
                                        <td style={{ textAlign: 'right' }}>{numberFormat(currentTicket.json.ticket.total_ttc)}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table style={{ width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center', padding: '20px 0px' }}>
                                            {currentTicket.json.footer}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        row.has_image > 0 &&
                        <div className="ticket w-100">
                            <img src={row.url} alt="ticket" />
                        </div>
                    }
                    {
                        !!row.has_text && !!!row.has_image > 0 &&
                        <p>
                            {row.text}
                        </p>
                    }

                </ModalBody>
            }
        </Modal>
    )
});

const TicketPage = observer((props) => {

    const pageReady = TicketStore.props.pageReady

    useEffect(() => {
        TicketStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={TicketStore.props.columns}
                                    listActions={TicketStore.props.listActions}
                                    data={TicketStore.props.data}
                                    pagination={TicketStore.props.pagination}
                                    onPaginationChange={(action, index) => TicketStore.onPaginationChange(action, index)}
                                    filters={TicketStore.props.filters}
                                    applyFilter={(values) => TicketStore.getFilteredData(values)}
                                    // toggleEdit={() => TicketStore.toggleEditCreateModal()}
                                    toggleSort={(column) => TicketStore.toggleSort(column)}
                                    toggleColumn={(column) => TicketStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <DetailModal
                                    isOpen={TicketStore.props.isOpenDetailModal}
                                    toggle={() => TicketStore.toggleDetailModal()}
                                    row={TicketStore.props.currentRow}
                                    currentTicket={TicketStore.props.currentTicket}
                                ></DetailModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default TicketPage