import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';


const Confirm = (props) => {

    const { title, text, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(false)
        close(true)
    }

    return (
        <Modal isOpen={open} backdrop="static" zIndex={1000000000000} centered={true} toggle={() => setOpen(!open)}>
            <ModalBody>
                <h5>
                    Confirmation {title}
                </h5>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <p>{parse(text)}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn-danger btn btn-sm mx-1" onClick={() => { setOpen(!open); close(false) }}>Annuler</button>
                        <button className="btn-success btn btn-sm mx-1" onClick={handleClick}>Confirmer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}


const modalConfirm = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<Confirm {...props} close={handleResolve} />, el);
    });
};

export default modalConfirm
