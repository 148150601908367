import { observable } from 'mobx'
import api from '../api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'
import ModeleTicketStore from './ModeleTicketStore'

const props = {
    user: {

    },
    successMobileRegister: false,
    emailToRegister: null,
    resetPasswordIsOk: null,
    accountActivated: null,
    loading: false
}

const UserStore = {

    props: props,

    /***** Methods ******/
    async init() {
        LoadingStore.on()
        console.log('init User')
        const session = JSON.parse(localStorage.getItem(process.env['REACT_APP_LOCAL_STORAGE_KEY']))
        // console.log(session)
        if (session && session.access_token) {
            // this.props.user = session
            await this.whoami()
            LoadingStore.off()
            return true
            // window.user = this
            // CampagneStore.getAllStatutCampagne(session.idCompteSMS)
        } else {
            LoadingStore.off()
            return false
        }
    },
    async whoami() {
        console.log('whoami')
        LoadingStore.on()
        const res = await api.post('/me')
        if (res && res.data && res.data.success) {
            res.data.user.isAuthenticated = true
            res.data.user.role_name = 'Admin'
            this.props.user = res.data.user
            // console.log(res.data.user)
            // localStorage.setItem('fidlink_api_user', JSON.stringify(res.data.user))
        }
        else if (typeof res.data.success == 'boolean') {
            this.props.user = {}
        }


        LoadingStore.off()
    },
    async login(values, callback, redirectToVerify) {
        LoadingStore.on()
        const res = await api.post('/login', values)
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')

            // if (!res.data.isVerified){
            //     redirectToVerify(values['email'])
            //     LoadingStore.off()
            //     return
            // }

            // res.data.user.isAuthenticated = true
            console.log('store', process.env['REACT_APP_LOCAL_STORAGE_KEY'])
            localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify({
                access_token: res.data.access_token,
                refresh_token: res.data.refresh_token,
            }))
            await this.whoami()

            this.props.user.isAuthenticated = true

            callback()

        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'info',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async forgot(values) {
        LoadingStore.on()
        const res = await api.post('/password/forgot', values)
        if (res && res.data.success) {
            console.log('FORGOT_SUCCESS')
            console.log(res.data)
            modalAlert({
                title: 'Succès',
                typeAlert: 'success',
                text: res.data.msg
            })
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async resetPassword(values, reset, setErrors) {
        this.props.loading = true
        const res = await api.post('/password/reset', values)

        if (res.data.success) {
            this.props.resetPasswordIsOk = true
        }
        else if (typeof res.data.success == 'boolean') {
            this.props.resetPasswordIsOk = false
            if (res.data.errors) {
                setErrors(res.data.errors)
            } else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: res.data.msg
                })
            }
            // reset()
        }
        this.props.loading = false
    },
    async editPassword(values, reset, toggle) {
        this.props.loading = true
        let to_send = {
            ...values,
            email: this.props.user.email
        }
        if (typeof reset == 'function')
            reset()
        const res = await api.post('/editPassword', to_send)

        if (res.data.success) {
            if (typeof toggle == 'function')
                toggle()
            modalAlert({
                title: 'Succès',
                typeAlert: 'success',
                text: res.data.msg
            })
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })

        }
        this.props.loading = false
    },
    async logout() {
        console.log('Logout user')
        localStorage.removeItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
        this.reset()
        ModeleTicketStore.reset()
    },
    isLoggedIn() {
        return this.props.user.isAuthenticated
    },
    isNotRestricted(roles) {
        if (roles)
            return !roles.includes(this.props.user.role_id)
        return true
    },
    reset() {
        this.props = props
    }
}

export default observable(UserStore)