import React from 'react'
import LoadingStore from '../stores/LoadingStore'
import { observer } from 'mobx-react'

const Loading = observer((props) => {
    const msg = LoadingStore.props.msg ? LoadingStore.props.msg : 'Chargement en cours ...'
    return (
        <>
            {
                LoadingStore.props.loading > 0 &&
                <>
                    <div className="modal-backdrop fade show d-flex jc ac" style={{zIndex : 100000000}}>
                    </div>
                    <div className="loading-wrapper">
                        <div className="card">
                            <div className="card-body d-flex flex-column jc ac">
                                <div className="hollow-dots-spinner " >
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                <div className="loading-text">{msg}</div>
                            </div>
                        </div>
                    </div>

                </>

            }
        </>
    )
})

export default Loading