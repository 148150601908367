import React, { useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import EmailEditor from 'react-email-editor';
import { Users, FileText,UserPlus } from 'react-feather';
import DashboardStore from '../../stores/DashboardStore';

const DashboardPage = observer((props) => {

    const pageReady = DashboardStore.props.pageReady
    const data = DashboardStore.props.data



    useEffect(() => {
        DashboardStore.init()
    }, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <h2>Résumé</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <Users size={40} className="text-orange"></Users>
                                            </div>
                                            <div className="col">
                                                <h3>{data.clients}</h3> clients
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <FileText size={40} className="text-orange"></FileText>
                                            </div>
                                            <div className="col">
                                                <h3>{data.tickets}</h3> tickets générés
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-auto">
                                                <UserPlus size={40} className="text-orange"></UserPlus>
                                            </div>
                                            <div className="col">
                                                <h3>{data.new_clients}</h3> nouveaux clients du jour
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default DashboardPage