import React from 'react'

const StoreIcon = () => {

    return (
        <svg version="1" style={{ height: '150px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
            <rect x="5" y="19" fill="#CFD8DC" width="38" height="19" />
            <rect x="5" y="38" fill="#B0BEC5" width="38" height="4" />
            <rect x="27" y="24" fill="#455A64" width="12" height="18" />
            <rect x="9" y="24" fill="#E3F2FD" width="14" height="11" />
            <rect x="10" y="25" fill="#1E88E5" width="12" height="9" />
            <path fill="#90A4AE" d="M36.5,33.5c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5S37,36.3,37,36v-2C37,33.7,36.8,33.5,36.5,33.5z" />
            <g fill="#c17405">
                <circle cx="24" cy="19" r="3" />
                <circle cx="36" cy="19" r="3" />
                <circle cx="12" cy="19" r="3" />
            </g>
            <g fill="#db8302">
                <path d="M40,6H8C6.9,6,6,6.9,6,8v3h36V8C42,6.9,41.1,6,40,6z" />
                <polygon points="37,11 32,11 33,19 39,19" />
                <polygon points="11,11 16,11 15,19 9,19" />
                <rect x="21" y="11" width="6" height="8" />
            </g>
            <g fill="#535e68">
                <circle cx="30" cy="19" r="3" />
                <path d="M45,19c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3L45,19z" />
                <circle cx="18" cy="19" r="3" />
                <path d="M3,19c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3L3,19z" />
            </g>
            <g fill="#5f6b76">
                <polygon points="32,11 27,11 27,19 33,19" />
                <polygon points="42,11 37,11 39,19 45,19" />
                <polygon points="16,11 21,11 21,19 15,19" />
                <polygon points="6,11 11,11 9,19 3,19" />
            </g>
        </svg>

    )
}

export default StoreIcon