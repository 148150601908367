
import React from 'react';
import './assets/scss/main.scss';
import AppRoute from './components/AppRoute';
import Loading from './components/Loading';

const App = (() => {

  return (
    <>
      <Loading></Loading>
      <AppRoute></AppRoute>
    </>
  );
})

export default App;
