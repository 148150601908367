import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { useFormik } from 'formik';
import Input from './Input';
import UserStore from '../stores/UserStore';
import myYup from '../utils/myYup';

const ModalEditPassword = (props) => {

    const { isOpen, toggle,submit } = props

    const { errors, setFieldValue, handleSubmit, values, setErrors, handleReset } = useFormik({
        initialValues: {
            password: '',
            new_password: '',
            c_new_password: ''
        },
        enableReinitialize: true,
        onSubmit: values => submit(values, handleReset,toggle),
        validationSchema: myYup.object().shape({
            password: myYup.string().required(),
            new_password: myYup.string().min(8,'Minimum 8 caractères').required(),
            c_new_password: myYup.string().required().oneOf([myYup.ref('new_password')], 'Confirmation mot de passe incorrecte')
        }),
        validateOnBlur: false,
        validateOnChange: false
    })


    const handleClick = () => {
        handleSubmit()
    }

    return (
        <Modal isOpen={isOpen} backdrop="static" zIndex={100000000} centered={true} toggle={() => toggle()}>
            <ModalBody>
                <h5>
                    Modification mot de passe
                </h5>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <Input
                                name="password"
                                label="Mot de passe actuel"
                                type="password"
                                className={"form-control"}
                                onChange={setFieldValue}
                                value={values['password']}
                                error={errors['password']}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <Input
                                name="new_password"
                                label="Nouveau mot de passe"
                                type="password"
                                className={"form-control"}
                                onChange={setFieldValue}
                                value={values['new_password']}
                                error={errors['new_password']}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <Input
                                name="c_new_password"
                                label="Confirmation nouveau mot de passe"
                                type="password"
                                className={"form-control"}
                                onChange={setFieldValue}
                                value={values['c_new_password']}
                                error={errors['c_new_password']}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn-danger btn btn-sm mx-1" onClick={() => { toggle() }}>Annuler</button>
                        <button className="btn-success btn btn-sm mx-1" onClick={handleClick}>Valider</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}


export default ModalEditPassword
