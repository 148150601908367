import React,{useEffect} from 'react'
import { observer } from 'mobx-react'
import { Redirect, Route } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsNotAuthenticated = observer((props) => {

    useEffect(() => {
        UserStore.init()
    }, [props.location])

    const {children} = props
    const isAuth = UserStore.props.user.isAuthenticated

    return <Route
        render={({ location }) => {
            if (!isAuth) {
                    return children
            } else {
                return <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: { name : 'Dashboard', from: location }
                    }}
                />
            }
        }
        }></Route>
})

export default IsNotAuthenticated