import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import {  X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
// import Datatable from '../../components/Datatable'
import DatatableSimple from '../../components/DatatableSimple'
import Input from '../../components/Input';
import ClientStore from '../../stores/ClientStore'
import ReseauStore from '../../stores/ReseauStore';
import myYup from '../../utils/myYup'

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row, fetchReseaux } = props

    const api_key = row.api_key
    // delete row.api_key
    // console.log(row)

    const { errors, setFieldValue, handleSubmit, values,setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values,setErrors),
        validationSchema: myYup.object().shape({
            nom: myYup.string().ensure().required(),
        }),
        validateOnBlur: false,
        validateOnChange: false
    })

    // console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Client</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Client</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom éditeur</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Enseigne associée</label>
                            <Input
                                name="reseau_id"
                                type="mySelect"
                                async={true}
                                fetch={fetchReseaux}
                                value={values['reseau_id']}
                                className={"form-control"}
                                onChange={setFieldValue}
                                error={errors['reseau_id']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Actif</label>
                            <Input
                                name="is_active"
                                type="switch"
                                value={values['is_active']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['is_active']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Nom contact</label>
                            <Input
                                name="contact_nom"
                                type="text"
                                value={values['contact_nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_nom']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Prénom contact</label>
                            <Input
                                name="contact_prenom"
                                type="text"
                                value={values['contact_prenom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_prenom']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Tel Mobile contact</label>
                            <Input
                                name="contact_telmobile"
                                type="text"
                                value={values['contact_telmobile']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_telmobile']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Tel Fixe contact</label>
                            <Input
                                name="contact_telfixe"
                                type="text"
                                value={values['contact_telfixe']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_telfixe']}
                            />
                        </div>
                        <div className="form-group col-md-12 col-12">
                            <label>Email contact</label>
                            <Input
                                name="contact_email"
                                type="email"
                                value={values['contact_email']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_email']}
                            />
                        </div>
                        <div className="form-group col-md-12 col-12">
                            <label>Clé API</label>
                            <Input
                                name="api_key"
                                disabled={true}
                                type="text"
                                value={api_key}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                        </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const ClientPage = observer((props) => {

    const pageReady = ClientStore.props.pageReady

    useEffect(() => {
        ClientStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={ClientStore.props.columns}
                                    listActions={ClientStore.props.listActions}
                                    data={ClientStore.props.data}
                                    pagination={ClientStore.props.pagination}
                                    onPaginationChange={(action, index) => ClientStore.onPaginationChange(action, index)}
                                    filters={ClientStore.props.filters}
                                    applyFilter={(values) => ClientStore.getFilteredData(values)}
                                    // toggleEdit={() => ClientStore.toggleEditCreateModal()}
                                    toggleSort={(column) => ClientStore.toggleSort(column)}
                                    toggleColumn={(column) => ClientStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <EditCreateModal 
                                    isOpen={ClientStore.props.isOpenEditCreateModal}
                                    fetchReseaux={(search) => ReseauStore.fetchReseaux(search)}
                                    toggle={() => ClientStore.toggleEditCreateModal()}
                                    row={ClientStore.props.currentRow}
                                    submit={(values,setErrors) => ClientStore.submitClient(values,setErrors)}
                                ></EditCreateModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default ClientPage