import { observable, toJS } from 'mobx'
import moment from 'moment'
import { CheckCircle, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import { ROLE_ADMIN, ROLE_EDITEUR, ROLE_RESEAU } from '../utils/constants'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    currentInfosRow: {},
    data: [],
    pagination: {},
    isOpenEditCreateModal: false,
    isOpenInfosModal: false,
}

const CommercantStore = observable({
    /***** Props ******/
    props: props,
    /***** Filter ******/
    getFilteredData(values) {
        // console.log(toJS(values))
        this.props.activeFilters = values
        this.loadData()
    },
    /***** Sort ******/
    toggleSort(column) {
        // console.log(column)
        for (let key in this.props.activeFilters) {

            if (column == key) {

                if (!this.props.activeFilters[key].sort) {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'ASC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'ASC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'DESC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'DESC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = null
                    // break
                }
            }
            else {
                this.props.activeFilters[key].sort = null
            }

        }

        this.loadData()
        console.log(toJS(this.props.filters))
    },
    /***** Toggle Columns ******/
    toggleColumn(column) {
        console.log('toggle')
        this.props.columns.map((item, index) => {
            if (item.key == column.key) {
                this.props.columns[index].isHidden = !this.props.columns[index].isHidden
                console.log(this.props.columns[index].isHidden)

            }
        })
    },
    /***** Pagination change ******/
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.loadData()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.loadData()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.loadData()
                break;

            default:
                break;
        }

    },
    /***** Initialisation ******/
    async init() {

        // Initialisation des columns 
        this.props.columns = [
            {
                key: 'nom',
                label: 'Nom commerçant',
                field: 'nom',
            },
            {
                key: 'city',
                label: 'Ville',
                field: 'city',
            },
            // {
            //     key: 'is_active',
            //     label: 'Actif',
            //     field: 'is_active',
            //     render: (value) =>
            //         value == 1 ?
            //             <CheckCircle size={20} className="text-success"></CheckCircle> :
            //             <XCircle size={20} className="text-danger"></XCircle>
            // },
            {
                key: 'created_at',
                label: 'Créée le',
                field: 'created_at',
                render: (value) => moment(value).format('DD/MM/YYYY')
            },
            {
                label: 'API Key',
                key: 'api_key',
                field: 'api_key',
                // isNotDisplay: true,
                isHidden: true
            },
            {
                label: 'Enseigne associée',
                key: 'reseau',
                field: 'reseau',
                render: (value) => {
                    return <span className="font-weight-bold">{value.nom}</span>
                }
            },
            {
                key: 'actions',
                label: '',
                listActions: [
                    {
                        label: 'Infos API',
                        name: 'infos',
                        roles: [ROLE_ADMIN, ROLE_EDITEUR, ROLE_RESEAU],
                        action: (row) => CommercantStore.toggleInfosModal(row)
                    },
                    {
                        label: 'Editer',
                        name: 'edit',
                        roles: [ROLE_EDITEUR, ROLE_RESEAU],
                        action: (row) => CommercantStore.toggleEditCreateModal(row)
                    }
                ]
            }
        ]

        // Initialisation des filtres
        this.props.filters = {
            id: {
                active: false,
                isSortable: true,
                label: 'Commercant',
                fetch: (search) => this.fetchCommercants(search),
                type: 'dropdown_list',
                operator: '=',
                value: null,
                sort: null
            },
            nom: {
                label: 'Commercant',
                isSortable: true,
                active: false,
                isNotFilterable: true,
                sort: null
            },
            contact_nom: {
                label: 'Nom contact',
                active: false,
                type: 'text',
                operator: '%%',
                value: null,
                sort: null
            },
            created_at: {
                label: 'Date création',
                isSortable: true,
                active: false,
                type: 'date',
                operator: 'between',
                value: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
                sort: null
            }
        }

        //Initialisation pagination
        this.props.pagination = {
            index: 1,
            offset: 20,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

        this.props.activeFilters = this.props.filters
        await this.loadData()
        this.props.pageReady = true
    },
    /***** Methods ******/
    toggleEditCreateModal(row) {
        if (row) {
            row.editeur = {
                id: row.editeur.id,
                name: row.editeur.nom
            }
            row.reseau = {
                id: row.reseau.id,
                name: row.reseau.nom
            }
            // delete row.reseau
        }
        // console.log(row)
        this.props.currentRow = row ? row : {}
        this.props.isOpenEditCreateModal = !this.props.isOpenEditCreateModal
    },
    async toggleInfosModal(row) {
        if (row) {
            const res = await this.fetchInfos(row.id)
            // console.log(res.data.success)
            if (res && res.success) {
                this.props.currentInfosRow = res.data
            }
        }
        else
            this.props.currentInfosRow = {}
        this.props.isOpenInfosModal = !this.props.isOpenInfosModal
    },
    async fetchInfos(id) {
        LoadingStore.on()
        const res = await api.post('/commercant/fetchInfos', { id: id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async loadData() {
        LoadingStore.on()
        const res = await api.post('/commercant/list', { pagination: this.props.pagination, filters: this.props.activeFilters })
        if (res.data && res.data.success) {
            this.props.data = res.data.data
            this.props.pagination.total = res.data.total
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    async fetchCommercants(search = null, reseau = null) {
        const key = 'nom'
        const res = await api.post('/commercant/listCommercants', { search: search, key: key, reseau: reseau })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.data.map((item => ({ id: item.id, name: item.nom })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return []
        }
    },
    async submitCommercant(values, setErrors) {
        LoadingStore.on()
        const res = await api.post('/commercant/store', values)
        if (res.data && res.data.success) {
            const fmData = new FormData();
            const config = {
                headers: { "content-type": "multipart/form-data" }
            };
            fmData.append("id", values['id']);
            fmData.append("shop_logo", values['shop_logo']);

            const res2 = await api.post(
                '/commercant/uploadShopLogo',
                fmData,
                config
            )
            if (res2.data && res.data.success) {
                modalAlert({
                    title: 'Succes',
                    typeAlert: 'success',
                    text: res.data.msg
                })
            }
            else if (res2.data.errors) {
                setErrors(res2.data.errors)
            }
            else if (typeof res2.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
            }

            this.toggleEditCreateModal()
        
            await this.loadData()
        }
        else if (res.data.errors) {
            setErrors(res.data.errors)
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default CommercantStore