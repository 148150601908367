import { observable, toJS } from 'mobx'
import api from '../api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    data: [],
}

const DashboardStore = observable({
    /***** Props ******/
    props: props,
    /***** Initialisation ******/
    async init() {

        // Initialisation des stats 
        await this.loadStats()
 
        this.props.pageReady = true
    },
    /***** Methods ******/
    async loadStats(){
        LoadingStore.on()
        const res = await api.post('/dashboard/getStats')
        if (res.data && res.data.success) {
            this.props.data = res.data.data
        }
        else if(typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default DashboardStore