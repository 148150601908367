import React, { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import { X } from 'react-feather';
import { Modal, ModalBody, Alert } from 'reactstrap';
import { faArrowLeft, faDesktop, faMobileAlt, faArrowRight, faCheck, faCheckCircle, faExclamationCircle, faPenAlt } from '@fortawesome/free-solid-svg-icons'
import DatatableSimple from '../../components/DatatableSimple'
import SurveyStore from '../../stores/SurveyStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import Input from '../../components/Input';
import StoreIcon from '../../components/StoreIcon'
import LoadingStore from '../../stores/LoadingStore';
import { ROLE_ADMIN, ROLE_EDITEUR, ROLE_RESEAU } from '../../utils/constants';
import UserStore from '../../stores/UserStore';
import ReseauStore from '../../stores/ReseauStore';
import { SurveyBuilder, SurveyPreview, SurveyResult } from '../../components/Survey'
import AnimateHeight from 'react-animate-height';

const ProgressBar = observer((props) => {
    const {
        action,
        currStep,
        steps,
        prevStep,
        nextStep,
        validateForm,
        submit,
        isLoading,
        success
    } = props


    const next = () => {
        validateForm().then((res) => {
            console.log('validate', res)
            if (!Object.keys(res).length)
                nextStep()
        })
    }

    const submitWizard = () => {
        validateForm().then((res) => {
            console.log('validate', res)
            if (!Object.keys(res).length)
                submit()
        })
    }


    return (
        <div className="d-flex progress-container w-10 pt-3 pb-2 px-3 border-bottom">

            <div className="col-2">
                {
                    currStep > 0 && !success &&
                    <button type="button" onClick={prevStep} class="btn btn-primary float-left" disabled={isLoading > 0}>
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"></FontAwesomeIcon>
                        }
                        Précédent
                    </button>
                }
            </div>

            <div className="col">
                <ul id="progressbar">
                    {
                        steps.map(
                            (item, index) => <li className={classnames({ 'passed': currStep > index, 'active': currStep == index })} style={{ width: (100 / steps.length) + '%' }}>
                                <div class="progress-item-circle">
                                    {/* {item.icon} */}
                                </div>
                                <strong>{item.title}</strong>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="col-2">
                {
                    currStep < (steps.length - 1) && !success &&
                    <button type="button" onClick={() => next()} class="btn btn-primary float-right" disabled={isLoading > 0}>
                        Suivant
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"></FontAwesomeIcon>
                        }
                    </button>
                }
                {
                    currStep == (steps.length - 1) && action == 'new' && !success &&
                    <button type="button" onClick={() => submitWizard()} class="btn btn-success float-right" disabled={isLoading > 0}>
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        }
                        Enregistrer
                    </button>
                }
                {
                    currStep == (steps.length - 1) && action == 'edit' && !success &&
                    <button type="button" onClick={() => submit()} class="btn btn-success float-right">
                        <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        Mettre à jour
                    </button>
                }
            </div>
        </div>

    )
})

export const ModalBuilder = observer((props) => {

    const { isOpenModalBuilder, toggleModalBuilder, setCurrEmail, currJson } = props



    // const saveSurvey = (text,json) => console.log(text,json)

    return (
        <Modal isOpen={isOpenModalBuilder} backdrop="static" className="modal-xxxl">
            {
                isOpenModalBuilder &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggleModalBuilder()}>
                        <X size={25}></X>
                    </button>

                    <h4>Edition Survey</h4>

                    <div className="row">
                        <div className="form-group col-12">
                            <SurveyBuilder json={currJson} save={(json) => setCurrEmail(json)} />
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

export const ActivateModal = observer((props) => {

    const {
        isOpenActivateModal,
        toggle,
        listShop,
        currRole,
        currentRow,
        toggleModeleSurvey,
        fetchShops,
        fetchReseaux
    } = props


    useEffect(() => {
        if (currRole != ROLE_ADMIN && currRole != ROLE_EDITEUR)
            fetchShops()
        else if (currentRow.reseau)
            fetchShops(currentRow.reseau)
    }, [])


    const activateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: currentRow.id,
            shops: currentRow.commercants ? currentRow.commercants : [],
            reseau: currentRow.reseau,
            is_active: currentRow.is_active,
        },
        onSubmit: (values) => toggleModeleSurvey(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    console.log('commercant', currentRow.commercants)

    const onChangeReseau = (field, val) => {
        activateForm.setFieldValue('shops', [])
        activateForm.setFieldValue(field, val)
        fetchShops(val)
    }

    return (
        <Modal
            tag="div"
            isOpen={isOpenActivateModal}
            className='modal-lg'
            unmountOnClose={false}
        >

            <ModalBody>
                <button className="close-circle" onClick={() => toggle()}>
                    <X size={25}></X>
                </button>
                <div className="mt-3 row">
                    <div className="col-md-12 mb-2 pb-3">
                        <p>Veuillez sélectionner les magasins pour lesquels vous souhaitez activer cette enquête</p>
                    </div>
                    {
                        <div className="col-12">
                            <div className="form-group d-block">
                                {!!activateForm.values['is_active']}
                                <Input
                                    label="Activer ce modèle"
                                    name="is_active"
                                    type="switch"
                                    // inverseChecked={true}
                                    className="form-control"
                                    onChange={(name, val) => activateForm.setFieldValue(name, val)}
                                    value={activateForm.values['is_active']}
                                    error={activateForm.errors['is_active']}
                                >
                                </Input>
                            </div>
                        </div>
                    }
                    <AnimateHeight
                        duration={500}
                        className="w-100 px-0"
                        height={activateForm.values['is_active'] > 0 ? 'auto' : 0}
                    >
                        {
                            currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR &&
                            <div className="col-md-12 mb-2 pb-3">
                                <Input
                                    name="reseau"
                                    label={'Choix de l\'enseigne'}
                                    type="mySelect"
                                    async={true}
                                    // depend={surveyForm.values['reseau']}
                                    fetch={fetchReseaux}
                                    value={activateForm.values['reseau']}
                                    className={"form-control"}
                                    onChange={onChangeReseau}
                                    error={activateForm.errors['reseau']}
                                />
                            </div>
                        }
                        <AnimateHeight
                            duration={500}
                            className="w-100 px-0"
                            height={activateForm.values['reseau'] || currRole != ROLE_ADMIN ? 'auto' : 0}
                        >
                            <div className="col-md-12 mb-2 pb-3">
                                <Input
                                    name="shops"
                                    col="col-md-4"
                                    icon={<StoreIcon></StoreIcon>}
                                    list={listShop}
                                    onChange={activateForm.setFieldValue}
                                    value={activateForm.values['shops']}
                                    error={activateForm.errors['shops']}
                                    type="multiple_choice"
                                ></Input>
                            </div>
                        </AnimateHeight>
                    </AnimateHeight>
                </div>
                <div className="form-group col-12">
                    <button onClick={() => activateForm.handleSubmit()} className="btn btn-primary">
                        Valider
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
});

export const ResultModal = observer((props) => {

    const { isOpen, toggle, currentResultRow, currentSurveyRow } = props


    // const saveSurvey = (text,json) => console.log(text,json)

    return (
        <Modal isOpen={isOpen} backdrop="static" className="modal-xxxl">
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>

                    <h4>Résulats Enquete</h4>

                    <div className="row">
                        <div className="form-group col-12">
                            <SurveyResult
                                currentResultRow={currentResultRow}
                                currentSurveyRow={currentSurveyRow}
                            />
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

export const EditCreateModal = observer((props) => {

    const {
        action,
        isOpen,
        toggle,
        steps,
        success,
        isLoading,
        getValidationSchema,
        nextStep,
        prevStep,
        currStep,
        predifinedModeles,
        editorOptions,
        submitSurvey,
        currentRow,
        listShop,
        currRole,
        fetchShops,
        fetchReseaux,

    } = props

    const [isOpenModalBuilder, setIsOpenModalBuilder] = useState(false)

    const toggleModalBuilder = () => setIsOpenModalBuilder(!isOpenModalBuilder)


    const onChangeReseau = (field, val) => {
        surveyForm.setFieldValue('shops', [])
        surveyForm.setFieldValue(field, val)
        console.log(val)
        surveyForm.setFieldValue('default_survey_id', val.default_survey_id)
        fetchShops(val)
    }

    const surveyForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: currentRow.id,
            is_active: 1,
            nom: currentRow.nom,
            json: currentRow.json,
            reseau: currentRow.reseau,
            shops: currentRow.commercants ? currentRow.commercants : [],

        },
        validationSchema: getValidationSchema(),
        onSubmit: (values) => submitSurvey(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    console.log('commercant', currentRow.commercants)


    const selectModele = (id, json) => {
        surveyForm.setFieldValue('modele_id', id)
        surveyForm.setFieldValue('json', json)
    }

    const setCurrSurvey = (json) => {
        surveyForm.setFieldValue('json', json)
        toggleModalBuilder()
    }

    useEffect(() => {
        if (currentRow.reseau)
            fetchShops(currentRow.reseau)
        else if (currRole != ROLE_ADMIN && currRole != ROLE_EDITEUR)
            fetchShops()

    }, [])

    return (
        <>
            <Modal
                tag="div"
                isOpen={isOpen}
                className="modal-xxxl"
                unmountOnClose={false}
            >
                {
                    isOpen &&
                    <ModalBody>
                        <button className="close-circle" onClick={() => toggle()}>
                            <X size={25}></X>
                        </button>

                        <h4>Nouvelle enquête</h4>

                        <ProgressBar
                            steps={steps}
                            action={action}
                            success={success}
                            isLoading={isLoading}
                            validateForm={() => surveyForm.validateForm()}
                            nextStep={() =>
                                nextStep(surveyForm.values,
                                    (name, value) => surveyForm.setFieldValue(name, value))}
                            prevStep={() => prevStep(surveyForm.values)}
                            currStep={currStep}
                            submit={() => surveyForm.handleSubmit()}
                        />
                        {
                            steps[currStep].title == 'Choix modèle' &&

                            <div className="mt-3 row">
                                <div className="col-md-3 mb-2 pb-3">
                                    <div
                                        onClick={() => selectModele(null)}
                                        className={classnames("card-modele rounded card d-flex h-100 jc ac ",
                                            { 'selected': !surveyForm.values['modele_id'] })}
                                    >


                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size={"2x"}
                                            className={classnames("check-modele", { 'visible': !surveyForm.values['modele_id'] })}>
                                        </FontAwesomeIcon>

                                        <button
                                            onClick={() => { }}
                                            className="btn btn-sm btn-primary ml-1">
                                            Ne pas utiliser de modèle
                                        </button>


                                    </div>
                                </div>
                                {
                                    predifinedModeles.map(item =>

                                        <div className="col-md-3 mb-2">
                                            <div
                                                onClick={() => selectModele(item.id, item.json)}
                                                className={classnames("card-modele rounded card", { 'selected': item.id == surveyForm.values['modele_id'] })}>


                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    size={"2x"}
                                                    className={classnames("check-modele", { 'visible': item.id == surveyForm.values['modele_id'] })}>
                                                </FontAwesomeIcon>

                                                <img class="card-img-top" src={item.preview} />
                                                <div class="card-body p-2 d-flex ac flex-column">
                                                    <h5 class="card-title">{item.nom}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            steps[currStep].title == 'Conception' &&
                            <>
                                {
                                    surveyForm.errors['json'] &&
                                    <div className="row mb-1">
                                        <div className="col">
                                            <Alert color="danger" isOpen={true}>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                                Le contenu de votre enquête est vide. Veuillez éditer une enquête.
                                            </Alert>
                                        </div>
                                    </div>

                                }
                                <div className="mt-3 row">
                                    <div className="col-md-4 p-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Nom de l'enquête</label>
                                                    <Input
                                                        name="nom"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={surveyForm.setFieldValue}
                                                        value={surveyForm.values['nom']}
                                                        error={surveyForm.errors['nom']}
                                                    >
                                                    </Input>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex jc mt-5 mb-4 ">
                                            <div className="col-md-6">
                                                <button onClick={() => toggleModalBuilder()} className="btn-block btn btn-primary card d-flex jc ac py-3">
                                                    <FontAwesomeIcon size={"2x"} icon={faPenAlt}></FontAwesomeIcon>
                                                    <span>Editer l'enquete</span>
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            (currRole != ROLE_ADMIN && currRole != ROLE_EDITEUR) &&
                                            !surveyForm.values['id'] &&
                                            <div className="col-12">
                                                <div className="form-group d-block">
                                                    <Input
                                                        label="Enregistrer en tant que brouillon"
                                                        name="is_active"
                                                        type="switch"
                                                        inverseChecked={true}
                                                        className="form-control"
                                                        onChange={(name, val) => surveyForm.setFieldValue(name, val)}
                                                        value={surveyForm.values['is_active']}
                                                        error={surveyForm.errors['is_active']}
                                                    >
                                                    </Input>
                                                </div>
                                            </div>
                                        }

                                        {

                                            (surveyForm.values['is_active'] > 0) &&
                                            <div className={classnames('row', { 'is-invalid': surveyForm.errors['shops'] })}>
                                                {
                                                    (typeof surveyForm.errors['shops'] == "string") &&
                                                    <div className="col-md-12 mb-2 pb-3">
                                                        <div className="invalid-feedback d-block" >{surveyForm.errors['shops']}</div>
                                                    </div>
                                                }

                                                {
                                                    currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR &&
                                                    <>
                                                        <div className="col-md-12 mb-2 pb-3">
                                                            <Input
                                                                name="reseau"
                                                                label={'Choix de l\'enseigne'}
                                                                type="mySelect"
                                                                async={true}
                                                                // depend={surveyForm.values['reseau']}
                                                                fetch={fetchReseaux}
                                                                value={surveyForm.values['reseau']}
                                                                className={"form-control"}
                                                                onChange={onChangeReseau}
                                                                error={surveyForm.errors['reseau']}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {

                                                    (currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR || currRole == ROLE_RESEAU) &&
                                                    <>
                                                        <AnimateHeight
                                                            duration={500}
                                                            className="w-100 px-0"
                                                            height={surveyForm.values['reseau'] ? 'auto' : 0}
                                                        >
                                                            <div className="col-12">

                                                                <div className="form-group d-block">
                                                                    <Input
                                                                        label={'Appliquer cette enquête à tous les magasins'}
                                                                        name="reseau.default_survey_id"
                                                                        type="switch"
                                                                        className="form-control"
                                                                        onChange={(name, val) => surveyForm.setFieldValue(name, val)}
                                                                        value={surveyForm.values['reseau']?.default_survey_id}
                                                                        error={surveyForm.errors['reseau']?.default_survey_id}
                                                                    >
                                                                    </Input>
                                                                </div>
                                                            </div>
                                                            <AnimateHeight
                                                                duration={500}
                                                                className="w-100 px-0"
                                                                height={surveyForm.values['reseau']?.default_survey_id <= 0 ? 'auto' : 0}
                                                            >
                                                                <div className="col-md-12 mb-2 pb-3">
                                                                    <label>
                                                                        Veuillez sélectionner les magasins pour lesquelles vous souhaitez activer cette enquête
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-12 mb-2 pb-3">
                                                                    <Input
                                                                        name="shops"
                                                                        col={"col-md-6"}
                                                                        icon={<StoreIcon></StoreIcon>}
                                                                        list={listShop}
                                                                        onChange={surveyForm.setFieldValue}
                                                                        value={surveyForm.values['shops']}
                                                                        error={surveyForm.errors['shops']}
                                                                        type="multiple_choice"
                                                                    ></Input>
                                                                </div>
                                                            </AnimateHeight>
                                                        </AnimateHeight>
                                                    </>
                                                }
                                            </div>
                                        }

                                    </div>
                                    <div className="col-md-8">
                                        <SurveyPreview json={surveyForm.values['json']}></SurveyPreview>
                                    </div>
                                </div>
                            </>
                        }
                    </ModalBody>
                }
            </Modal>
            <ModalBuilder
                isOpenModalBuilder={isOpenModalBuilder}
                toggleModalBuilder={() => toggleModalBuilder()}
                currJson={surveyForm.values['json']}
                setCurrEmail={(json) => setCurrSurvey(json)}
            >

            </ModalBuilder>
        </>
    )
});

const SurveyPage = observer((props) => {

    const pageReady = SurveyStore.props.pageReady

    useEffect(() => {
        SurveyStore.init()

    }, [])

    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={SurveyStore.props.columns}
                                    listActions={SurveyStore.props.listActions}
                                    data={SurveyStore.props.data}
                                    pagination={SurveyStore.props.pagination}
                                    onPaginationChange={(action, index) => SurveyStore.onPaginationChange(action, index)}
                                    filters={SurveyStore.props.filters}
                                    applyFilter={(values) => SurveyStore.getFilteredData(values)}
                                    toggleEdit={() => SurveyStore.toggleEditCreateModal()}
                                    toggleSort={(column) => SurveyStore.toggleSort(column)}
                                    toggleColumn={(column) => SurveyStore.toggleColumn(column)}
                                ></DatatableSimple>
                                {
                                    SurveyStore.props.isOpenEditCreateModal &&
                                    <EditCreateModal
                                        action={'new'}
                                        fetchShops={(reseau) => SurveyStore.fetchShops(reseau)}
                                        currRole={UserStore.props.user.role_id}
                                        fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                        listShop={SurveyStore.props.listShop}
                                        isOpen={SurveyStore.props.isOpenEditCreateModal}
                                        toggle={() => SurveyStore.toggleEditCreateModal()}
                                        steps={SurveyStore.getWizardSteps()}
                                        success={SurveyStore.props.successEditCreate}
                                        currentRow={SurveyStore.props.currentRow}
                                        currJson={SurveyStore.props.currJson}
                                        isLoading={LoadingStore.props.loading}
                                        nextStep={(values, setFieldValue) => SurveyStore.nextStep(values, setFieldValue)}
                                        prevStep={(values, setFieldValue) => SurveyStore.prevStep(values, setFieldValue)}
                                        currStep={SurveyStore.props.currStep}
                                        predifinedModeles={SurveyStore.props.predifinedModeles}
                                        getValidationSchema={() => SurveyStore.getValidationSchema()}
                                        submitSurvey={(values) => SurveyStore.submitSurvey(values)}
                                    ></EditCreateModal>
                                }
                                {
                                    SurveyStore.props.isOpenResultModal &&
                                    <ResultModal
                                        isOpen={SurveyStore.props.isOpenResultModal}
                                        toggle={() => SurveyStore.toggleResultModal()}
                                        currentSurveyRow={SurveyStore.props.currentSurveyRow}
                                        currentResultRow={SurveyStore.props.currentResultRow}
                                    ></ResultModal>
                                }
                                {SurveyStore.props.isOpenActivateModal &&
                                    <ActivateModal
                                        currRole={UserStore.props.user.role_id}
                                        fetchShops={(reseau) => SurveyStore.fetchShops(reseau)}
                                        currentRow={SurveyStore.props.currentRow}
                                        listShop={SurveyStore.props.listShop}
                                        fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                        toggleModeleSurvey={(values) => SurveyStore.toggleModeleSurvey(values)}
                                        isOpenActivateModal={SurveyStore.props.isOpenActivateModal}
                                        toggle={() => SurveyStore.toggleActivateModal()}
                                    ></ActivateModal>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default SurveyPage

