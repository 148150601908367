import { useFormik } from 'formik'
import React from 'react'
import { Lock, Mail } from 'react-feather'
import Input from '../../components/Input'
import UserStore from '../../stores/UserStore'
import Logo from '../../components/Logo'

const ForgotPasswordPage = (props) => {

    const { errors, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            email: null,
        },
        onSubmit: values => UserStore.forgot(values),
        validateOnBlur: false,
        validateOnChange: false
    })

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <Logo className="dematick-orange-grey"></Logo>
                </div>
                <div className="card">
                    <form className="card-body login-card-body">
                    <div className="input-group mb-3">
                            <p>Afin de réinitialiser votre mot de passe, veuillez saisir votre adresse email</p>
                        </div>
                        <div className="input-group mb-3">
                            <Input
                                name="email"
                                type="email"
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['email']}
                            />

                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <Mail></Mail>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={handleSubmit} className="btn btn-primary btn-block">Envoyer le lien de réinitialisation</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default ForgotPasswordPage