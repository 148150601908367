import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
import DatatableSimple from '../../components/DatatableSimple'
import Input from '../../components/Input';
import EditeurStore from '../../stores/EditeurStore'
import myYup from '../../utils/myYup'

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row } = props

    const { errors, setFieldValue, handleSubmit, values,setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values,setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    // console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Editeur</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Editeur</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom éditeur</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Actif</label>
                            <Input
                                name="is_active"
                                type="switch"
                                value={values['is_active']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['is_active']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Nom contact</label>
                            <Input
                                name="contact_nom"
                                type="text"
                                value={values['contact_nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_nom']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Prénom contact</label>
                            <Input
                                name="contact_prenom"
                                type="text"
                                value={values['contact_prenom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_prenom']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Tel Mobile contact</label>
                            <Input
                                name="contact_telmobile"
                                type="text"
                                value={values['contact_telmobile']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_telmobile']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Tel Fixe contact</label>
                            <Input
                                name="contact_telfixe"
                                type="text"
                                value={values['contact_telfixe']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_telfixe']}
                            />
                        </div>
                        <div className="form-group col-md-12 col-12">
                            <label>Email contact</label>
                            <Input
                                name="contact_email"
                                type="email"
                                value={values['contact_email']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['contact_email']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                        </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const EditeurPage = observer((props) => {

    const pageReady = EditeurStore.props.pageReady

    useEffect(() => {
        EditeurStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={EditeurStore.props.columns}
                                    listActions={EditeurStore.props.listActions}
                                    data={EditeurStore.props.data}
                                    pagination={EditeurStore.props.pagination}
                                    filters={EditeurStore.props.filters}
                                    applyFilter={(values) => EditeurStore.getFilteredData(values)}
                                    toggleEdit={() => EditeurStore.toggleEditCreateModal()}
                                    doubleClick={(row) => EditeurStore.toggleEditCreateModal(row)}
                                    toggleSort={(column) => EditeurStore.toggleSort(column)}
                                    toggleColumn={(column) => EditeurStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <EditCreateModal
                                    isOpen={EditeurStore.props.isOpenEditCreateModal}
                                    toggle={() => EditeurStore.toggleEditCreateModal()}
                                    row={EditeurStore.props.currentRow}
                                    submit={(values,setErrors) => EditeurStore.submitEditeur(values,setErrors)}
                                ></EditCreateModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default EditeurPage