import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import {  X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
// import Datatable from '../../components/Datatable'
import DatatableSimple from '../../components/DatatableSimple'
import Input from '../../components/Input';
import CaisseStore from '../../stores/CaisseStore'
import CommercantStore from '../../stores/CommercantStore';
import EditeurStore from '../../stores/EditeurStore';
import ReseauStore from '../../stores/ReseauStore';
import myYup from '../../utils/myYup'

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row,fetchEditeurs,fetchReseaux, fetchCommercants } = props

    const api_key = row.api_key
    // delete row.api_key
    // console.log(row)

    const { errors, setFieldValue, handleSubmit, values, setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values,setErrors),
        // validationSchema: myYup.object().shape({
        //     nom: myYup.string().ensure().required(),
        // }),
        validateOnBlur: false,
        validateOnChange: false
    })

    const onChangeEditeur = (name,val) =>{

        setFieldValue(name,val)
        setFieldValue('reseau',null)
        setFieldValue('commercant',null)
    }

    const onChangeReseau = (name,val) =>{

        setFieldValue(name,val)
        setFieldValue('commercant',null)
    }

    // console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Caisse</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Caisse</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom caisse</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Editeur associé</label>
                            <Input
                                name="editeur"
                                type="mySelect"
                                async={true}
                                fetch={fetchEditeurs}
                                value={values['editeur']}
                                className={"form-control"}
                                onChange={onChangeEditeur}
                                error={errors['editeur']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Enseigne associée</label>
                            <Input
                                name="reseau"
                                type="mySelect"
                                async={true}
                                depend={values['editeur']}
                                fetch={fetchReseaux}
                                value={values['reseau']}
                                className={"form-control"}
                                onChange={onChangeReseau}
                                error={errors['reseau']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Commercant associé</label>
                            <Input
                                name="commercant"
                                type="mySelect"
                                async={true}
                                depend={values['reseau']}
                                fetch={fetchCommercants}
                                value={values['commercant']}
                                className={"form-control"}
                                onChange={setFieldValue}
                                error={errors['commercant']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Active</label>
                            <Input
                                name="is_active"
                                type="switch"
                                value={values['is_active']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['is_active']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Type</label>
                            <Input
                                name="type"
                                type="text"
                                value={values['type']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['type']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Modèle</label>
                            <Input
                                name="modele"
                                type="text"
                                value={values['modele']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['modele']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>N° de série</label>
                            <Input
                                name="serial_number"
                                type="text"
                                value={values['serial_number']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['serial_number']}
                            />
                        </div>
                        <div className="form-group col-md-12 col-12">
                            <label>Clé API</label>
                            <Input
                                name="api_key"
                                disabled={true}
                                type="text"
                                value={api_key}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                        </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const CaissePage = observer((props) => {

    const pageReady = CaisseStore.props.pageReady

    useEffect(() => {
        CaisseStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={CaisseStore.props.columns}
                                    listActions={CaisseStore.props.listActions}
                                    data={CaisseStore.props.data}
                                    pagination={CaisseStore.props.pagination}
                                    onPaginationChange={(action, index) => CaisseStore.onPaginationChange(action, index)}
                                    filters={CaisseStore.props.filters}
                                    applyFilter={(values) => CaisseStore.getFilteredData(values)}
                                    toggleEdit={() => CaisseStore.toggleEditCreateModal()}
                                    toggleSort={(column) => CaisseStore.toggleSort(column)}
                                    toggleColumn={(column) => CaisseStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <EditCreateModal 
                                    isOpen={CaisseStore.props.isOpenEditCreateModal}
                                    fetchEditeurs={(search) => EditeurStore.fetchEditeurs(search)}
                                    fetchReseaux={(search,editeur) => ReseauStore.fetchReseaux(search,editeur)}
                                    fetchCommercants={(search,reseau) => CommercantStore.fetchCommercants(search,reseau)}
                                    toggle={() => CaisseStore.toggleEditCreateModal()}
                                    row={CaisseStore.props.currentRow}
                                    submit={(values,setStatus) => CaisseStore.submitCaisse(values,setStatus)}
                                ></EditCreateModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default CaissePage