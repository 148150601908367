import React, { useState } from 'react'
import SimpleBar from 'simplebar-react'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { faChevronLeft, faCity, faCubes, faHome, faReceipt, faStore, faTasks, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import UserStore from '../stores/UserStore'
import { ROLE_COMMERCANT, ROLE_SUPER_ADMIN, ROLE_EDITEUR, ROLE_RESEAU } from '../utils/constants'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const AppMenu = observer((props) => {

    const isNotRestricted = (roles) => {
        let val = UserStore.isNotRestricted(roles)
        console.log(roles, val)
        return val
    }

    const menu = [
        {
            // header: isCampagne ? 'CLIENTS' : 'PILOTER',
            children: [
                {
                    name: 'Tableau de bord',
                    icon: <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>,
                    link: {
                        pathname: '/dashboard',
                        state: { name: 'Tableau de bord' }

                    },
                },
                {
                    name: 'Utilisateurs',
                    icon: <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>,
                    restrict: [ROLE_EDITEUR, ROLE_RESEAU, ROLE_COMMERCANT],
                    link: {
                        pathname: '/utilisateurs',
                        state: { name: 'Utilisateurs' },
                    },
                },
                {
                    name: 'Editeurs',
                    icon: <FontAwesomeIcon icon={faCubes}></FontAwesomeIcon>,
                    restrict: [ROLE_EDITEUR, ROLE_RESEAU, ROLE_COMMERCANT],
                    link: {
                        pathname: '/editeurs',
                        state: { name: 'Editeurs' },
                    },
                },
                {
                    name: 'Enseignes',
                    icon: <FontAwesomeIcon icon={faCity}></FontAwesomeIcon>,
                    restrict: [ROLE_RESEAU, ROLE_COMMERCANT],
                    link: {
                        pathname: '/reseaux',
                        state: { name: 'Enseignes' },
                    },
                },
                {
                    name: 'Magasins',
                    icon: <FontAwesomeIcon icon={faStore}></FontAwesomeIcon>,
                    restrict: [ROLE_COMMERCANT],
                    link: {
                        pathname: '/commercants',
                        state: { name: 'Magasins' },
                    },
                },
                {
                    name: 'Clients',
                    icon: <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>,
                    // restrict: [ROLE_EDITEUR],
                    link: {
                        pathname: '/clients',
                        state: { name: 'Clients' }
                    },
                },
                {
                    name: 'Tickets',
                    icon: <FontAwesomeIcon icon={faReceipt}></FontAwesomeIcon>,
                    // restrict: [ROLE_EDITEUR],
                    children: [
                        {
                            name: 'Tickets clients',
                            icon: <FontAwesomeIcon size={'sm'} icon={faCircle}></FontAwesomeIcon>,
                            link: {
                                pathname: '/tickets/client',
                                state: { name: 'Tickets clients' }
                            },
                        },
                        {
                            name: 'Modèles de tickets',
                            icon: <FontAwesomeIcon size={'sm'} icon={faCircle}></FontAwesomeIcon>,
                            restrict: [ROLE_COMMERCANT],
                            link: {
                                pathname: '/tickets/modele',
                                state: { name: 'Modèles de tickets' }
                            }
                        }
                    ]

                },
                {
                    name: 'Enquêtes',
                    icon: <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>,
                    // restrict: [ROLE_EDITEUR],
                    link: {
                        pathname: '/enquetes',
                        state: { name: 'Enquêtes' }
                    },
                },
            ]
        }

    ]

    return <SimpleBar style={{ height: '100%' }} autoHide={false} className="sidebar-nav">
        <ul className="nav nav-pills nav-sidebar flex-column">
            {
                menu.filter(item => isNotRestricted(item.restrict)).map((item, index) => (
                    <>
                        {
                            item.header && <li key={index} className="nav-title">{item.header}</li>
                        }
                        {
                            item.children.filter(item => isNotRestricted(item.restrict)).map((child, index) => (
                                <MenuItem key={index} isNotRestricted={(val) => isNotRestricted(val)} {...child}></MenuItem>
                            ))
                        }
                    </>
                ))
            }
        </ul>
    </SimpleBar>
})

const MenuItem = (props) => {

    const {
        name,
        link,
        children,
        icon,
        _blank
    } = props

    return (
        <>
            {
                children &&
                <SubMenuItem {...props}></SubMenuItem>

            }
            {
                !children &&
                <>
                    {
                        !_blank &&
                        <li className="nav-item">
                            <NavLink to={link} className="nav-link" activeClassName="active">
                                {icon}
                                <p>{name}</p>
                            </NavLink>
                        </li>
                    }
                    {
                        _blank &&
                        <a target="_blank" href={link} className="nav-link">
                            {icon}
                            {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
                            <p>{name}</p>
                        </a>
                    }
                </>

            }
        </>
    )


}

const SubMenuItem = (props) => {

    const {
        name,
        children,
        isNotRestricted,
        icon
    } = props

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <li className={classnames('nav-item nav-dropdown has-treeview ', { 'menu-open': menuOpen })}>
            <a className="nav-link nav-dropdown-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                <p>
                    {icon}
                    {name}
                    <FontAwesomeIcon icon={faChevronLeft} className="right"></FontAwesomeIcon>
                </p>
            </a>
            <ul className="nav nav-treeview">
                {children.filter(item => isNotRestricted(item.restrict)).map((item, index) => (
                    <li key={index} className="nav-item">

                        <NavLink to={item.link} className="nav-link">
                            {item.icon}
                            {item.name}
                        </NavLink>

                    </li>

                ))
                }
            </ul>
        </li>
    )
}

export default AppMenu