
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import { AlertTriangle, Feather, ShoppingBag, X } from 'react-feather';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatatableSimple from '../../components/DatatableSimple'
import ModeleTicketStore from '../../stores/ModeleTicketStore'
import { faArrowLeft, faArrowRight, faCheck, faDesktop, faCheckCircle, faMobileAlt, faExclamationCircle, faPenAlt, faTimes, faStore } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import LoadingStore from '../../stores/LoadingStore';
import { CheckCircle, XCircle } from 'react-feather'
import EmailEditor from 'react-email-editor';
import Switch from "react-switch";
import Input from '../../components/Input';
import { toJS } from 'mobx';
import ModeleCards from '../../components/ModeleCards';
import StoreIcon from '../../components/StoreIcon'
import { ROLE_ADMIN, ROLE_EDITEUR, ROLE_RESEAU } from '../../utils/constants';
import UserStore from '../../stores/UserStore';
import ReseauStore from '../../stores/ReseauStore';
import AnimateHeight from 'react-animate-height';
import ModeleTable from '../../components/ModeleTable';

export const DetailModal = observer((props) => {

    const { isOpen, toggle, row, items } = props


    // console.log(row)

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>

                    <h4>Détail Ticket {row.no_ticket}</h4>
                    <table className="table table-sm">
                        <tr>
                            <th>Nom</th>
                            <th>Quantité</th>
                            <th>Prix unitaire</th>
                            <th>Montant</th>
                        </tr>
                        {
                            items.map(item =>
                                <tr>
                                    <td>{item.label}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.prix_unitaire}</td>
                                    <td>{item.montant}</td>
                                </tr>
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="font-weight-bold">TOTAL HT</td>
                            <td className="font-weight-bold">{row.total_ht}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="font-weight-bold">TOTAL TTC</td>
                            <td className="font-weight-bold">{row.total_ttc}</td>
                        </tr>
                    </table>
                </ModalBody>
            }
        </Modal>
    )
});

const ProgressBar = observer((props) => {
    const {
        action,
        currStep,
        steps,
        prevStep,
        nextStep,
        validateForm,
        submit,
        isLoading,
        success
    } = props


    const next = () => {
        validateForm().then((res) => {
            console.log('validate', res)
            if (!Object.keys(res).length)
                nextStep()
        })
    }

    const submitWizard = () => {
        validateForm().then((res) => {
            console.log('validate', res)
            if (!Object.keys(res).length)
                submit()
        })
    }


    return (
        <div className="d-flex progress-container w-10 pt-3 pb-2 px-3 border-bottom">

            <div className="col-2">
                {
                    currStep > 0 && !success &&
                    <button type="button" onClick={prevStep} class="btn btn-primary float-left" disabled={isLoading > 0}>
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"></FontAwesomeIcon>
                        }
                        Précédent
                    </button>
                }
            </div>

            <div className="col">
                <ul id="progressbar">
                    {
                        steps.map(
                            (item, index) => <li className={classnames({ 'passed': currStep > index, 'active': currStep == index })} style={{ width: (100 / steps.length) + '%' }}>
                                <div class="progress-item-circle">
                                    {/* {item.icon} */}
                                </div>
                                <strong>{item.title}</strong>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="col-2">
                {
                    currStep < (steps.length - 1) && !success &&
                    <button type="button" onClick={() => next()} class="btn btn-primary float-right" disabled={isLoading > 0}>
                        Suivant
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"></FontAwesomeIcon>
                        }
                    </button>
                }
                {
                    currStep == (steps.length - 1) && action == 'new' && !success &&
                    <button type="button" onClick={() => submitWizard()} class="btn btn-success float-right" disabled={isLoading > 0}>
                        {
                            isLoading > 0 &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading > 0 &&
                            <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        }
                        Enregistrer
                    </button>
                }
                {
                    currStep == (steps.length - 1) && action == 'edit' && !success &&
                    <button type="button" onClick={() => submit()} class="btn btn-success float-right">
                        <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        Mettre à jour
                    </button>
                }
            </div>
        </div>

    )
})

export const EditCreateModal = observer((props) => {

    const {
        action,
        isOpen,
        toggle,
        steps,
        success,
        isLoading,
        getValidationSchema,
        nextStep,
        prevStep,
        currStep,
        predifinedModeles,
        editorOptions,
        submitModeleTicket,
        currentRow,
        listShop,
        currRole,
        fetchReseaux,
        fetchShops

    } = props

    useEffect(() => {
        if (currentRow.reseau)
            fetchShops(currentRow.reseau)
        else if (currRole != ROLE_ADMIN && currRole != ROLE_EDITEUR)
            fetchShops()

    }, [])

    const [isOpenModalBuilder, setIsOpenModalBuilder] = useState(false)

    const toggleModalBuilder = () => setIsOpenModalBuilder(!isOpenModalBuilder)

    const newEmailTicketFrom = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: currentRow.id,
            modele_id: null,
            is_active: 1,
            titre: currentRow.nom,
            objet: currentRow.objet,
            json: currentRow.json,
            shops: currentRow.commercants ? currentRow.commercants : [],
            contenu: currentRow.contenu,
            is_default: currentRow.is_default,
            reseau: currentRow.reseau,
        },
        validationSchema: getValidationSchema(),
        onSubmit: (values) => submitModeleTicket(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    console.log('commercant', currentRow.commercants)

    const onChangeReseau = (field, val) => {
        newEmailTicketFrom.setFieldValue('shops', [])
        newEmailTicketFrom.setFieldValue(field, val)
        console.log(val)
        newEmailTicketFrom.setFieldValue('is_default', 0)
        fetchShops(val)
    }
    const selectModele = (id) => newEmailTicketFrom.setFieldValue('modele_id', id)

    const setCurrEmail = (contenu, json) => {

        newEmailTicketFrom.setFieldValue('contenu', contenu)
        newEmailTicketFrom.setFieldValue('json', json)

    }


    return (
        <>
            <Modal
                tag="div"
                isOpen={isOpen}
                className="modal-xxxl"
                unmountOnClose={false}
            >
                {
                    isOpen &&
                    <ModalBody>
                        <button className="close-circle" onClick={() => toggle()}>
                            <X size={25}></X>
                        </button>

                        <h4>Nouveau Modele Email Ticket</h4>

                        <ProgressBar
                            steps={steps}
                            action={action}
                            success={success}
                            isLoading={isLoading}
                            validateForm={() => newEmailTicketFrom.validateForm()}
                            nextStep={() =>
                                nextStep(newEmailTicketFrom.values,
                                    (name, value) => newEmailTicketFrom.setFieldValue(name, value))}
                            prevStep={() => prevStep(newEmailTicketFrom.values)}
                            currStep={currStep}
                            submit={() => newEmailTicketFrom.handleSubmit()}
                        />
                        {
                            steps[currStep].title == 'Choix modèle' &&

                            <div className="mt-3 row">
                                <div className="col-md-3 mb-2 pb-3">
                                    <div
                                        onClick={() => selectModele(null)}
                                        className={classnames("card-modele rounded card d-flex h-100 jc ac ",
                                            { 'selected': !newEmailTicketFrom.values['modele_id'] })}
                                    >


                                        <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            size={"2x"}
                                            className={classnames("check-modele", { 'visible': !newEmailTicketFrom.values['modele_id'] })}>
                                        </FontAwesomeIcon>

                                        <button
                                            onClick={() => { }}
                                            className="btn btn-sm btn-primary ml-1">
                                            Ne pas utiliser de modèle
                                        </button>


                                    </div>
                                </div>
                                {
                                    predifinedModeles.map(item =>

                                        <div className="col-md-3 mb-2">
                                            <div
                                                onClick={() => selectModele(item.id)}
                                                className={classnames("card-modele rounded card", { 'selected': item.id == newEmailTicketFrom.values['modele_id'] })}>


                                                <FontAwesomeIcon
                                                    icon={faCheckCircle}
                                                    size={"2x"}
                                                    className={classnames("check-modele", { 'visible': item.id == newEmailTicketFrom.values['modele_id'] })}>
                                                </FontAwesomeIcon>

                                                <img class="card-img-top" src={item.preview} />
                                                <div class="card-body p-2 d-flex ac flex-column">
                                                    <h5 class="card-title">{item.nom}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        }
                        {
                            steps[currStep].title == 'Conception' &&
                            <>
                                {
                                    newEmailTicketFrom.errors['contenu'] &&
                                    <div className="row mb-1">
                                        <div className="col">
                                            <Alert color="danger" isOpen={true}>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                                Le contenu de votre Email est vide. Veuillez éditer un Email.
                                            </Alert>
                                        </div>
                                    </div>

                                }
                                <div className="mt-3 row">
                                    <div className="col-md-4 p-3">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Nom du modèle</label>
                                                    <Input
                                                        name="titre"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={newEmailTicketFrom.setFieldValue}
                                                        value={newEmailTicketFrom.values['titre']}
                                                        error={newEmailTicketFrom.errors['titre']}
                                                    >
                                                    </Input>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Afin d'éditer le modèle veuillez cliquer ci-dessous</label>

                                                    <div className="d-flex jc mt-0 mb-2 ">
                                                        <div className="col-md-6">
                                                            <button onClick={() => toggleModalBuilder()} className="btn-block btn btn-primary card d-flex jc ac py-3">
                                                                <FontAwesomeIcon size={"2x"} icon={faPenAlt}></FontAwesomeIcon>
                                                                <span>Editer le modèle</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-12">
                                                <div className="form-group">
                                                    <label>Objet de l'email</label>
                                                    <Input
                                                        name="objet"
                                                        type="text"
                                                        className="form-control"
                                                        onChange={newEmailTicketFrom.setFieldValue}
                                                        value={newEmailTicketFrom.values['objet']}
                                                        error={newEmailTicketFrom.errors['objet']}
                                                    >
                                                    </Input>
                                                </div>
                                            </div> */}
                                            {
                                                // (currRole != ROLE_ADMIN && currRole != ROLE_EDITEUR) &&
                                                !newEmailTicketFrom.values['id'] &&

                                                <div className="col-12">
                                                    <div className="form-group d-block">
                                                        <Input
                                                            label="Activer ce modèle"
                                                            name="is_active"
                                                            type="switch"
                                                            // inverseChecked={true}
                                                            className="form-control"
                                                            onChange={(name, val) => newEmailTicketFrom.setFieldValue(name, val)}
                                                            value={newEmailTicketFrom.values['is_active']}
                                                            error={newEmailTicketFrom.errors['is_active']}
                                                        >
                                                        </Input>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                newEmailTicketFrom.values['is_active'] > 0 &&
                                                <div className={classnames('col-12', { 'is-invalid': newEmailTicketFrom.errors['shops'] })}>
                                                    {
                                                        (typeof newEmailTicketFrom.errors['shops'] == "string") &&
                                                        <div className="col-md-12 mb-2 pb-3">
                                                            <div className="invalid-feedback d-block">{newEmailTicketFrom.errors['shops']}</div>
                                                        </div>
                                                    }

                                                    {

                                                        (currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR) &&
                                                        <div className="w-100 mb-2 pb-3">

                                                            <Input
                                                                name="reseau"
                                                                label={'Choix l\'enseigne'}
                                                                type="mySelect"
                                                                async={true}
                                                                // depend={newEmailTicketFrom.values['reseau']}
                                                                fetch={fetchReseaux}
                                                                value={newEmailTicketFrom.values['reseau']}
                                                                className={"form-control"}
                                                                onChange={onChangeReseau}
                                                                error={newEmailTicketFrom.errors['reseau']}
                                                            />
                                                        </div>
                                                    }
                                                    {

                                                        (currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR || currRole == ROLE_RESEAU) &&
                                                        <>
                                                            <AnimateHeight
                                                                duration={500}
                                                                className="w-100 px-0"
                                                                height={newEmailTicketFrom.values['reseau'] ? 'auto' : 0}
                                                            >
                                                                <div className="col-12">

                                                                    <div className="form-group d-block">
                                                                        <Input
                                                                            label={'Appliquer cette enquête à tous les magasins'}
                                                                            name="is_default"
                                                                            type="switch"
                                                                            className="form-control"
                                                                            onChange={(name, val) => newEmailTicketFrom.setFieldValue(name, val)}
                                                                            value={newEmailTicketFrom.values['is_default']}
                                                                            error={newEmailTicketFrom.errors['is_default']}
                                                                        >
                                                                        </Input>
                                                                    </div>
                                                                </div>
                                                                <AnimateHeight
                                                                    duration={500}
                                                                    className="w-100 px-0"
                                                                    height={!!!newEmailTicketFrom.values['is_default'] ? 'auto' : 0}
                                                                >
                                                                    <div className="col-md-12 mb-2 pb-3">
                                                                        <label>
                                                                            Veuillez sélectionner les magasins pour lesquels vous souhaitez activer ce ticket
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-md-12 mb-2 pb-3">
                                                                        <Input
                                                                            name="shops"
                                                                            col={"col-md-6"}
                                                                            icon={<StoreIcon></StoreIcon>}
                                                                            list={listShop}
                                                                            onChange={newEmailTicketFrom.setFieldValue}
                                                                            value={newEmailTicketFrom.values['shops']}
                                                                            error={newEmailTicketFrom.errors['shops']}
                                                                            type="multiple_choice"
                                                                        ></Input>
                                                                    </div>
                                                                </AnimateHeight>
                                                            </AnimateHeight>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <PreviewEmail html={newEmailTicketFrom.values['contenu']}></PreviewEmail>
                                    </div>
                                </div>
                            </>
                        }
                    </ModalBody>
                }
            </Modal>
            <ModalBuilder
                isOpenModalBuilder={isOpenModalBuilder}
                toggleModalBuilder={() => toggleModalBuilder()}
                currJson={newEmailTicketFrom.values['json']}
                editorOptions={editorOptions}
                setCurrEmail={(contenu, json) => setCurrEmail(contenu, json)}
            >

            </ModalBuilder>
        </>
    )
});

export const ActivateModal = observer((props) => {

    const {
        isOpenActivateModal,
        toggle,
        listShop,
        currRole,
        currentRow,
        toggleModeleTicket,
        fetchShops,
        fetchReseaux
    } = props


    useEffect(() => {
        // if (currRole != ROLE_ADMIN)
        //     fetchShops()
        // else if (currentRow.reseau)
        console.log(toJS(currentRow.reseau))
        fetchShops(currentRow.reseau)
    }, [])


    const activateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: currentRow.id,
            shops: currentRow.commercants ? currentRow.commercants : [],
            reseau: currentRow.reseau,
            is_default: currentRow.is_default,
            is_active: currentRow.is_active,
        },
        onSubmit: (values) => toggleModeleTicket(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    console.log('commercant', currentRow.commercants)

    const onChangeReseau = (field, val) => {
        activateForm.setFieldValue('shops', [])
        activateForm.setFieldValue(field, val)
        fetchShops(val)
    }

    return (
        <Modal
            tag="div"
            isOpen={isOpenActivateModal}
            className='modal-lg'
            unmountOnClose={false}
        >

            <ModalBody>
                <button className="close-circle" onClick={() => toggle()}>
                    <X size={25}></X>
                </button>
                <div className="mt-3 row">
                    <div className="col-md-12 mb-2 pb-3">
                        <p>Veuillez sélectionner les magasins pour lesquels vous souhaitez activer le modèle : <b>{currentRow.nom}</b></p>
                    </div>
                    {
                        <div className="col-12">
                            <div className="form-group d-block">
                                {!!activateForm.values['is_active']}
                                <Input
                                    label="Activer ce modèle"
                                    name="is_active"
                                    type="switch"
                                    // inverseChecked={true}
                                    className="form-control"
                                    onChange={(name, val) => activateForm.setFieldValue(name, val)}
                                    value={activateForm.values['is_active']}
                                    error={activateForm.errors['is_active']}
                                >
                                </Input>
                            </div>
                        </div>
                    }
                    <AnimateHeight
                        duration={500}
                        className="w-100 px-0"
                        height={activateForm.values['is_active'] > 0 ? 'auto' : 0}
                    >
                        {
                            (currRole == ROLE_ADMIN || currRole == ROLE_EDITEUR) &&
                            <div className="col-md-12 mb-2 pb-3">
                                <Input
                                    name="reseau"
                                    label={'Choix du l\'enseigne'}
                                    type="mySelect"
                                    async={true}
                                    // depend={newEmailTicketFrom.values['reseau']}
                                    fetch={fetchReseaux}
                                    value={activateForm.values['reseau']}
                                    className={"form-control"}
                                    onChange={onChangeReseau}
                                    error={activateForm.errors['reseau']}
                                />
                            </div>
                        }
                        <div className="col-12">

                            <div className="form-group d-block">
                                <Input
                                    label={'Appliquer cette enquête à tous les magasins'}
                                    name="is_default"
                                    type="switch"
                                    className="form-control"
                                    onChange={(name, val) => activateForm.setFieldValue(name, val)}
                                    value={activateForm.values['is_default']}
                                    error={activateForm.errors['is_default']}
                                >
                                </Input>
                            </div>
                        </div>
                        <AnimateHeight
                            duration={500}
                            className="w-100 px-0"
                            height={activateForm.values['is_default'] <= 0 ? 'auto' : 0}
                        >
                            <div className="col-md-12 mb-2 pb-3">
                                <Input
                                    name="shops"
                                    col="col-md-4"
                                    icon={<StoreIcon></StoreIcon>}
                                    list={listShop}
                                    onChange={activateForm.setFieldValue}
                                    value={activateForm.values['shops']}
                                    error={activateForm.errors['shops']}
                                    type="multiple_choice"
                                ></Input>
                            </div>
                        </AnimateHeight>
                    </AnimateHeight>
                </div>
                <div className="form-group col-12">
                    <button onClick={() => activateForm.handleSubmit()} className="btn btn-primary">
                        Valider
                    </button>
                </div>
            </ModalBody>
        </Modal >
    )
});

export const PreviewModal = observer((props) => {

    const {
        isOpenPreviewModal,
        togglePreviewModal,
        currentPreview
    } = props

    return (
        <Modal
            tag="div"
            isOpen={isOpenPreviewModal}
            className="modal-xxxl"
            unmountOnClose={false}
        >

            <ModalBody>
                <button className="close-circle" onClick={() => togglePreviewModal()}>
                    <X size={25}></X>
                </button>
                <h4>Aperçu {currentPreview.nom}</h4>
                <div className="col-12">
                    <PreviewEmail html={currentPreview.contenu}></PreviewEmail>
                </div>
            </ModalBody>
        </Modal>
    )
});

const ModalBuilder = observer((props) => {

    const {
        isOpenModalBuilder,
        toggleModalBuilder,
        currJson,
        editorOptions,
        setCurrEmail,

    } = props

    const exportDesign = () => {
        designer.current.exportHtml(design => {
            setCurrEmail(design.html, design.design)
        })
        toggleModalBuilder()
    }

    const designer = useRef(null)
    let timer = null;

    // console.log(preview.json)
    const load = () => {
        console.log('LOAD DESIGNER')

        //Permet de vérifier que unlayer est bien chargé
        if (designer && designer.current && window.unlayer) {
            console.log(designer)
            if (timer) {
                clearInterval(timer)
                console.log('INNER LOAD DESIGNER')
                console.log('load', JSON.parse(currJson))
                designer.current.loadDesign(JSON.parse(currJson))
                return true;
            }

        }
        return false;
    }

    useEffect(() => {
        // console.log('use effect LOAD DESIGNER open modal')
        // console.log(toJS(preview))
        // console.log('useEffect')
        if (!load() && currJson)
            timer = setTimeout(load, 500);
    }, [isOpenModalBuilder])

    return (
        <>
            <Modal isOpen={isOpenModalBuilder} backdrop="static" unmountOnClose={false} className="modal-xxxl">
                <ModalBody>
                    <div className="d-flex justify-content-end">
                        <div className="m-1">
                            <button className="btn btn-danger" onClick={() => toggleModalBuilder()}>
                                <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                                Annuler
                            </button>
                        </div>
                        <div className="m-1">
                            <button className="btn btn-success" tab="2" onClick={() => { exportDesign() }}>
                                <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                Valider
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <EmailEditor
                                projectId={36246}
                                options={editorOptions}
                                // saveDesign={(json) => setPreview(json, null)}
                                onLoad={() => load()}
                                ref={designer}
                                minHeight="85vh" />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
})

const PreviewEmail = observer((props) => {

    const {
        html
    } = props

    const frame_mobile = useRef(null)
    const frame_desktop = useRef(null)


    const [isMobile, setIsMobile] = useState(false)




    useEffect(() => {
        console.log('changeMob')
        if (frame_mobile.current) {
            let iframeDoc = frame_mobile.current.contentWindow ?
                frame_mobile.current.contentWindow :
                frame_mobile.current.contentDocument.document ?
                    frame_mobile.current.contentDocument.document :
                    frame_mobile.current.contentDocument;

            iframeDoc.document.open();
            iframeDoc.document.write(
                html
            );
            iframeDoc.document.close();
        }
        if (frame_desktop.current) {
            let iframeDoc = frame_desktop.current.contentWindow ?
                frame_desktop.current.contentWindow :
                frame_desktop.current.contentDocument.document ?
                    frame_desktop.current.contentDocument.document :
                    frame_desktop.current.contentDocument;

            iframeDoc.document.open();
            iframeDoc.document.write(
                html
            );
            iframeDoc.document.close();
        }
    }, [html, isMobile])

    return (
        <>
            <h5 className="text-center">Aperçu</h5>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center mb-2">
                        <div className="px-3">
                            <FontAwesomeIcon icon={faDesktop} className="text-old" size="lg"></FontAwesomeIcon>
                        </div>
                        <div >
                            <Switch
                                checked={isMobile}
                                onChange={() => setIsMobile(!isMobile)}
                                onColor="#fd7e13"
                                onHandleColor="#ebedef"
                                offColor="#fd7e13"
                                offHandleColor="#ebedef"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={16}
                                width={30}
                                className="react-switch"
                            />
                        </div>
                        <div className="px-3">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-old" size="lg"></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
            </div>
            {
                isMobile &&
                <div className="row jc">
                    <div className="mobile-content">
                        <div className="mobile-preview-samsung">
                            <div class="mobile-wrapper">
                                <div style={{ height: '100%' }}>
                                    {
                                        !html &&
                                        <div className="no-preview h2 text-center">
                                            Aucun contenu
                                        </div>
                                    }
                                    {
                                        html && html.length > 0 &&
                                        <iframe ref={frame_mobile}>

                                        </iframe>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                !isMobile &&
                <div className="d-flex jc">
                    <div className="row col-md-12">
                        <div className="desktop-header rounded-top col-12"></div>
                        <div class="desktop-wrapper col-12 rounded-bottom">
                            <div style={{ height: '100%' }}>
                                {
                                    !html &&
                                    <div className="no-preview h2 text-center">
                                        Aucun contenu
                                    </div>
                                }
                                {
                                    html && html.length > 0 &&
                                    <iframe ref={frame_desktop}>

                                    </iframe>
                                }

                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
})

const ModeleTicketPage = observer((props) => {

    const pageReady = ModeleTicketStore.props.pageReady



    useEffect(() => {
        ModeleTicketStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                {ModeleTicketStore.props.shops_with_no_modele.length > 0 &&
                                    <Alert color="info" isOpen={ModeleTicketStore.props.enableDefaultModele} className="infos-coupons">
                                        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                        {ModeleTicketStore.props.shops_with_no_modele.length > 1 ? 'Les magasins ' : 'Le magasin '}
                                        {
                                            ModeleTicketStore.props.shops_with_no_modele.map(
                                                item => <b>{item}{', '}</b>
                                            )
                                        }
                                        {ModeleTicketStore.props.shops_with_no_modele.length > 1 ? 'n\'ont ' : 'n\'a '}
                                        aucun modèle d'email actif.
                                        Le modèle par défaut sera donc utilisé.
                                        {' '}
                                        {console.log(toJS(ModeleTicketStore.props.listShop))}
                                        <a href="javascript:void(0);" role="button" onClick={() => ModeleTicketStore.previewDefaultModele()}>Voir le modele par défaut.</a>
                                    </Alert>
                                }
                                <DatatableSimple
                                    columns={ModeleTicketStore.props.columns}
                                    listActions={ModeleTicketStore.props.listActions}
                                    data={ModeleTicketStore.props.data}
                                    pagination={ModeleTicketStore.props.pagination}
                                    onPaginationChange={(action, index) => ModeleTicketStore.onPaginationChange(action, index)}
                                    filters={ModeleTicketStore.props.filters}
                                    applyFilter={(values) => ModeleTicketStore.getFilteredData(values)}
                                    toggleEdit={() => ModeleTicketStore.toggleEditCreateModal()}
                                    toggleSort={(column) => ModeleTicketStore.toggleSort(column)}
                                    toggleColumn={(column) => ModeleTicketStore.toggleColumn(column)}
                                ></DatatableSimple>
                                {ModeleTicketStore.props.isOpenEditCreateModal &&
                                    <EditCreateModal
                                        action={'new'}
                                        currRole={UserStore.props.user.role_id}
                                        listShop={ModeleTicketStore.props.listShop}
                                        fetchShops={(reseau) => ModeleTicketStore.fetchShops(reseau)}
                                        fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                        isOpen={ModeleTicketStore.props.isOpenEditCreateModal}
                                        toggle={() => ModeleTicketStore.toggleEditCreateModal()}
                                        steps={ModeleTicketStore.getWizardSteps()}
                                        success={ModeleTicketStore.props.successEditCreate}
                                        currContenu={ModeleTicketStore.props.currContenu}
                                        currentRow={ModeleTicketStore.props.currentRow}
                                        currJson={ModeleTicketStore.props.currJson}
                                        isLoading={LoadingStore.props.loading}
                                        nextStep={(values, setFieldValue) => ModeleTicketStore.nextStep(values, setFieldValue)}
                                        prevStep={(values, setFieldValue) => ModeleTicketStore.prevStep(values, setFieldValue)}
                                        currStep={ModeleTicketStore.props.currStep}
                                        predifinedModeles={ModeleTicketStore.props.predifinedModeles}
                                        getValidationSchema={() => ModeleTicketStore.getValidationSchema()}
                                        editorOptions={ModeleTicketStore.getEditoroptions()}
                                        submitModeleTicket={(values) => ModeleTicketStore.submitModeleTicket(values)}
                                    ></EditCreateModal>
                                }
                                <PreviewModal
                                    currentPreview={ModeleTicketStore.props.currentPreview}
                                    isOpenPreviewModal={ModeleTicketStore.props.isOpenPreviewModal}
                                    togglePreviewModal={() => ModeleTicketStore.togglePreviewModal()}
                                ></PreviewModal>
                                {ModeleTicketStore.props.isOpenActivateModal &&
                                    <ActivateModal
                                        currRole={UserStore.props.user.role_id}
                                        fetchShops={(reseau) => ModeleTicketStore.fetchShops(reseau)}
                                        currentRow={ModeleTicketStore.props.currentRow}
                                        listShop={ModeleTicketStore.props.listShop}
                                        fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                        toggleModeleTicket={(values) => ModeleTicketStore.toggleModeleTicket(values)}
                                        isOpenActivateModal={ModeleTicketStore.props.isOpenActivateModal}
                                        toggle={() => ModeleTicketStore.toggleActivateModal()}
                                    ></ActivateModal>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default ModeleTicketPage