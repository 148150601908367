import React from 'react'

const Logo = (props) => {

    const { className } = props

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1386 314">
            <path className="right"
                d="M281.5,98.94c4.32,0,8.24,3.83,8.24,9l.3,131.62a9.07,9.07,0,0,1-5,8.28L176,299.62a7.35,7.35,0,0,1-3.24.76c-4.32,0-8.24-3.83-8.24-9l-.3-131.62a9.07,9.07,0,0,1,5-8.28l109-51.78a7.35,7.35,0,0,1,3.24-.76Zm-4.06,15.9L176.6,162.74l.26,121.83,100.84-47.9-.26-121.83" />
            <path className="left"
                d="M27.08,240.27l-.3-131.62c0-6.48,6-10.84,11.43-8.33l109.26,51.21a9.18,9.18,0,0,1,5,8.28l.3,131.62c0,6.48-6,10.84-11.43,8.33L32.09,248.55A9.1,9.1,0,0,1,27.08,240.27ZM39.51,114.84l-.26,121.83,100.84,47.9.26-121.83L39.51,114.84" />
            <path className="top"
                d="M157.57,10.86a7.45,7.45,0,0,1,3.32.76l115,55.57c6.52,3.17,6.56,13.3,0,16.47L161.19,139.83a8.37,8.37,0,0,1-3.32.8,7.45,7.45,0,0,1-3.32-.76l-115-55.57C33,81.14,33,71,39.51,67.84L154.25,11.66a7.64,7.64,0,0,1,3.32-.8Zm0,14L53,76l104.81,50.69,104.6-51.26L157.61,24.82" />
            <g className="dematick-logo-text">
                <path
                    d="M342.5,102h46.4c43.6,0,74.4,28.4,74.4,68.6s-30.8,68.8-74.4,68.8H342.5Zm46.4,123.2c34.4,0,58.6-22.6,58.6-54.6,0-31.8-24.2-54.4-58.6-54.4H358.1v109Z" />
                <path d="M510.7,116.79V162h74.6v14.8H510.7v47.8h82.8v14.8H494.9V102h98.6v14.8Z" />
                <path d="M626.3,102h15.6l51,95.8,51-95.8h15.6v137.4H743.9V135.59l-51,95.8-51-95.8v103.8H626.3Z" />
                <path d="M895.1,208.19H818.5l-13.8,31.2h-17L848.3,102h17l60.6,137.4h-17Zm-6.4-14.8-31.8-72.2-32,72.2Z" />
                <path d="M978.1,116.79H931.9V102h108v14.8H993.7v122.6H978.1Z" />
                <path d="M1067.69,102h15.6v137.4h-15.6Z" />
                <path
                    d="M1112.49,170.59c0-38.6,32.4-71,70.4-71a69.44,69.44,0,0,1,49.4,21l-10.6,10.8c-10-10.6-23.8-17.4-38.8-17.4-29.6,0-54.6,26-54.6,56.6,0,30.8,25,56.8,54.6,56.8,15,0,28.8-6.8,38.8-17.4l10.6,10.8c-12.8,12.8-30.4,21-49.4,21C1144.89,241.79,1112.49,209.19,1112.49,170.59Z" />
                <path d="M1259.49,102h15.6v76l69.6-76h20.6l-57.8,62.8,61.6,74.6h-20.2l-52.2-64-21.6,22.8v41.2h-15.6Z" />
            </g>
        </svg>

    )
}

export default Logo