import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { User, X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
// import Datatable from '../../components/Datatable'
import DatatableSimple from '../../components/DatatableSimple'
import Input from '../../components/Input';
import CommercantStore from '../../stores/CommercantStore'
import EditeurStore from '../../stores/EditeurStore';
import ReseauStore from '../../stores/ReseauStore';
import UserStore from '../../stores/UserStore';
import { ROLE_ADMIN, ROLE_EDITEUR } from '../../utils/constants';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import api from '../../api'

export const InfosModal = observer((props) => {

    const { isOpen, toggle, row } = props


    return (
        <Modal isOpen={isOpen} centered={true} size={'lg'}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    <h4>Infos API</h4>
                    <div className="row">
                        <div className="col-12">
                            <h5>Id commerçant</h5>
                            <div className="w-100  d-flex jc">
                                <span className="font-weight-bold text-orange" style={{ fontSize: '1.5rem' }}>{row.id}</span>

                            </div>
                        </div>
                        <div className="col-12">
                            <h5>Api Key</h5>
                            <div className="w-100  d-flex jc">
                                <span className="font-weight-bold text-orange" style={{ fontSize: '1.1rem' }}>{row.api_key}</span>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            }
        </Modal >
    )
});

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row, fetchEditeurs, fetchReseaux, role_id } = props
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [currFile, setCurrFile] = useState({});
    const api_key = row.api_key
    // delete row.api_key
    // console.log(row)

    const { errors, setFieldValue, handleSubmit, values, setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values, setErrors),
        // validationSchema: myYup.object().shape({
        //     nom: myYup.string().ensure().required(),
        // }),
        validateOnBlur: false,
        validateOnChange: false
    })

    useEffect(() => {
        setImageUrl(row.shop_logo_url)
    },[isOpen])

    const onChangeEditeur = (name, val) => {

        setFieldValue(name, val)
        setFieldValue('reseau', null)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const customRequest = async options => {
        const { onSuccess, onError, file, onProgress } = options;

        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
            //   onUploadProgress: event => {
            //     const percent = Math.floor((event.loaded / event.total) * 100);
            //     setProgress(percent);
            //     if (percent === 100) {
            //       setTimeout(() => setProgress(0), 1000);
            //     }
            //     onProgress({ percent: (event.loaded / event.total) * 100 });
            //   }
        };
        fmData.append("shop_logo", file);
        try {
            const res = await api.post(
                '/commercant/uploadShopLogo',
                fmData,
                config
            );

            onSuccess("Ok");

        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Une erreur est survenue");
            onError({ err });
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error('L\'image doit etre au format JPG ou PNG !');
            setErrors({ 'shop_logo': ['L\'image doit etre au format JPG ou PNG !'] })
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error('La taille de l\'image ne doit pas dépasser 2MB');
            setErrors({'shop_logo': ['La taille de l\'image ne doit pas dépasser 2MB'] })
        }

        if (isJpgOrPng && isLt2M){
            getBase64(file, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
            setFieldValue('shop_logo',file)
        }

        return false
    };

    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }


    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            console.log(info.file)
            setCurrFile(info.file)
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };


    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Commerçant</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Commerçant</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom commerçant</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12 shop-logo">
                            <label>Logo</label>
                            <ImgCrop aspect={19/8}>
                                <Upload
                                    name="logo"
                                    customRequest={customRequest}
                                    listType="picture-card"
                                    showUploadList={false}
                                    action={process.env['REACT_APP_UPLOAD_LOGO_SHOP_URL']}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        <div>
                                            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faPlus} />}
                                            <div
                                                style={{
                                                    marginTop: 8,
                                                }}
                                            >
                                                Ajouter une image
                                            </div>
                                        </div>
                                    )}
                                </Upload>
                            </ImgCrop>
                            {errors['shop_logo'] && <div className="invalid-feedback d-block">{errors['shop_logo']}</div>}
                        </div>
                        {
                            (role_id == ROLE_EDITEUR || role_id == ROLE_ADMIN) &&
                            < div className="form-group col-12">
                                <label>Enseigne associée</label>
                                <Input
                                    name="reseau"
                                    type="mySelect"
                                    async={true}
                                    depend={values['editeur']}
                                    fetch={fetchReseaux}
                                    value={values['reseau']}
                                    className={"form-control"}
                                    onChange={setFieldValue}
                                    error={errors['reseau']}
                                />
                            </div>
                        }
                        {
                            row.api_key &&
                            <div className="form-group col-md-12 col-12">
                                <label>Clé API</label>
                                <Input
                                    name="api_key"
                                    disabled={true}
                                    type="text"
                                    value={api_key}
                                    className="form-control"
                                />
                            </div>
                        }
                        <div className="form-group col-12">
                            <label>Ligne adresse 1</label>
                            <Input
                                name="address_1"
                                type="text"
                                value={values['address_1']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['address_1']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Ligne adresse 2</label>
                            <Input
                                name="address_2"
                                type="text"
                                value={values['address_2']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['address_2']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Code postal</label>
                            <Input
                                name="postal_code"
                                type="text"
                                value={values['postal_code']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['postal_code']}
                            />
                        </div>
                        <div className="form-group col-md-6 col-12">
                            <label>Ville</label>
                            <Input
                                name="city"
                                type="text"
                                value={values['city']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['city']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Entête du ticket</label>
                            <Input
                                name="receipt_header"
                                type="textarea"
                                rows={5}
                                value={values['receipt_header']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['receipt_header']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Pied du ticket</label>
                            <Input
                                name="receipt_footer"
                                type="textarea"
                                rows={5}
                                value={values['receipt_footer']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['receipt_footer']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                            </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal >
    )
});

const CommercantPage = observer((props) => {

    const pageReady = CommercantStore.props.pageReady
    const role_id = UserStore.props.user.role_id

    useEffect(() => {
        CommercantStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    currentRole={UserStore.props.user.role_id}
                                    columns={CommercantStore.props.columns}
                                    listActions={CommercantStore.props.listActions}
                                    data={CommercantStore.props.data}
                                    pagination={CommercantStore.props.pagination}
                                    onPaginationChange={(action, index) => CommercantStore.onPaginationChange(action, index)}
                                    filters={CommercantStore.props.filters}
                                    applyFilter={(values) => CommercantStore.getFilteredData(values)}
                                    toggleEdit={() => CommercantStore.toggleEditCreateModal()}
                                    toggleSort={(column) => CommercantStore.toggleSort(column)}
                                    toggleColumn={(column) => CommercantStore.toggleColumn(column)}
                                ></DatatableSimple>
                                <EditCreateModal
                                    role_id={role_id}
                                    isOpen={CommercantStore.props.isOpenEditCreateModal}
                                    fetchEditeurs={(search) => EditeurStore.fetchEditeurs(search)}
                                    fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                    toggle={() => CommercantStore.toggleEditCreateModal()}
                                    row={CommercantStore.props.currentRow}
                                    submit={(values, setErrors) => CommercantStore.submitCommercant(values, setErrors)}
                                ></EditCreateModal>
                                <InfosModal
                                    isOpen={CommercantStore.props.isOpenInfosModal}
                                    toggle={() => CommercantStore.toggleInfosModal()}
                                    row={CommercantStore.props.currentInfosRow}
                                ></InfosModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default CommercantPage