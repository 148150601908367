import { useFormik } from 'formik';
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import AnimateHeight from 'react-animate-height';
import { X } from 'react-feather';
import { Modal, ModalBody } from 'reactstrap';
import DatatableSimple from '../../components/DatatableSimple'
import Input from '../../components/Input';
import CommercantStore from '../../stores/CommercantStore';
import EditeurStore from '../../stores/EditeurStore';
import ReseauStore from '../../stores/ReseauStore';
import UtilisateurStore from '../../stores/UtilisateurStore'
import { roleLabel, ROLE_COMMERCANT, ROLE_EDITEUR, ROLE_RESEAU } from '../../utils/constants';
import myYup from '../../utils/myYup'

export const EditCreateModal = observer((props) => {

    const { isOpen, toggle, submit, row, fetchReseaux, fetchEditeurs, fetchCommercants } = props

    const { errors, setFieldValue, handleSubmit, values, setErrors } = useFormik({
        enableReinitialize: true,
        initialValues: row,
        onSubmit: values => submit(values, setErrors),
        validateOnBlur: false,
        validateOnChange: false
    })

    const onChangeEditeur = (name, val) => {
        setFieldValue(name, val)
        setFieldValue('reseau', {})
        setFieldValue('commercant', {})
    }

    const onChangeReseau = (name, val) => {
        setFieldValue(name, val)
        setFieldValue('commercant', {})
    }

    return (
        <Modal isOpen={isOpen}>
            {
                isOpen &&
                <ModalBody>
                    <button className="close-circle" onClick={() => toggle()}>
                        <X size={25}></X>
                    </button>
                    {
                        row.id > 0 &&
                        <h4>Modifcation Utilisateur</h4>
                    }
                    {
                        !row.id &&
                        <h4>Ajout Utilisateur</h4>
                    }
                    <div className="row">
                        <div className="form-group col-12">
                            <label>Nom</label>
                            <Input
                                name="nom"
                                type="text"
                                value={values['nom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['nom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Prénom</label>
                            <Input
                                name="prenom"
                                type="text"
                                value={values['prenom']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['prenom']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Email</label>
                            <Input
                                name="email"
                                type="text"
                                value={values['email']}
                                className="form-control"
                                onChange={setFieldValue}
                                error={errors['email']}
                            />
                        </div>
                        <div className="form-group col-12">
                            <label>Rôle</label>
                            <Input
                                name="role"
                                type="mySelect"
                                list={Object.keys(roleLabel).map((item) => ({ id: parseInt(item), name: roleLabel[item] }))}
                                value={values['role']}
                                className={"form-control"}
                                onChange={setFieldValue}
                                error={errors['role']}
                            />
                        </div>
                        <AnimateHeight
                            duration={500}
                            className="w-100 px-0"
                            height={([ROLE_RESEAU, ROLE_COMMERCANT]).includes(values['role']?.id) ? 'auto' : 0}
                        >
                            <div className="form-group col-12">
                                <label>Editeur</label>
                                <Input
                                    name="editeur"
                                    type="mySelect"
                                    async={true}
                                    depend={values['role']}
                                    fetch={fetchEditeurs}
                                    value={values['editeur']}
                                    className={"form-control"}
                                    onChange={onChangeEditeur}
                                    error={errors['editeur']}
                                />
                            </div>
                        </AnimateHeight>
                        <AnimateHeight
                            duration={500}
                            className="w-100 px-0"
                            height={([ROLE_RESEAU, ROLE_COMMERCANT]).includes(values['role']?.id) ? 'auto' : 0}
                        >
                            <div className="form-group col-12">
                                <label>Reseau</label>
                                <Input
                                    name="reseau"
                                    type="mySelect"
                                    async={true}
                                    depend={values['editeur']}
                                    fetch={fetchReseaux}
                                    value={values['reseau']}
                                    className={"form-control"}
                                    onChange={onChangeReseau}
                                    error={errors['reseau']}
                                />
                            </div>
                        </AnimateHeight>
                        <AnimateHeight
                            duration={500}
                            className="w-100 px-0"
                            height={([ROLE_COMMERCANT]).includes(values['role']?.id) ? 'auto' : 0}
                        >
                            <div className="form-group col-12">
                                <label>Commerçant</label>
                                <Input
                                    name="commercant"
                                    type="mySelect"
                                    async={true}
                                    depend={values['reseau']}
                                    fetch={fetchCommercants}
                                    value={values['commercant']}
                                    className={"form-control"}
                                    onChange={setFieldValue}
                                    error={errors['commercant']}
                                />
                            </div>
                        </AnimateHeight>

                        <div className="form-group col-12">
                            <button onClick={handleSubmit} className="btn btn-primary">
                                Valider
                            </button>
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
});

const UtilisateurPage = observer((props) => {

    const pageReady = UtilisateurStore.props.pageReady

    useEffect(() => {
        UtilisateurStore.init()
    }, [])


    return (
        <>
            {
                pageReady &&

                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DatatableSimple
                                    columns={UtilisateurStore.props.columns}
                                    listActions={UtilisateurStore.props.listActions}
                                    data={UtilisateurStore.props.data}
                                    pagination={UtilisateurStore.props.pagination}
                                    filters={UtilisateurStore.props.filters}
                                    applyFilter={(values) => UtilisateurStore.getFilteredData(values)}
                                    toggleEdit={() => UtilisateurStore.toggleEditCreateModal()}
                                    doubleClick={(row) => UtilisateurStore.toggleEditCreateModal(row)}
                                    toggleSort={(column) => UtilisateurStore.toggleSort(column)}
                                    toggleColumn={(column) => UtilisateurStore.toggleColumn(column)}

                                ></DatatableSimple>
                                <EditCreateModal
                                    isOpen={UtilisateurStore.props.isOpenEditCreateModal}
                                    toggle={() => UtilisateurStore.toggleEditCreateModal()}
                                    row={UtilisateurStore.props.currentRow}
                                    fetchEditeurs={(search) => EditeurStore.fetchEditeurs(search)}
                                    fetchReseaux={(search, editeur) => ReseauStore.fetchReseaux(search, editeur)}
                                    fetchCommercants={(search, reseau) => CommercantStore.fetchCommercants(search, reseau)}
                                    submit={(values, setErrors) => UtilisateurStore.submitEditeur(values, setErrors)}
                                ></EditCreateModal>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default UtilisateurPage