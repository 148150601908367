
import React, { useState } from 'react'
import { ChevronDown, Lock, LogOut, Menu, User } from 'react-feather'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import UserStore from '../../stores/UserStore'
import AppMenu from '../../components/AppMenu'
import { observer } from 'mobx-react'
import Logo from '../../components/Logo'
import { roleLabel } from '../../utils/constants'
import ModalEditPassword from '../../components/ModalEditPassword'

const SidebarLayout = observer(withRouter((props) => {

    const { children } = props
    const { state } = props.location
    const role_id = UserStore.props.user.role_id
    const societe = UserStore.props.user.societe
    // console.log(props)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isOpenModalPassword, setIsOpenModalPassword] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const toggleSidebar = (e) => {
        e.preventDefault()
        console.log('toggle')
        // console.log(wrapperRef)
        // const el = wrapperRef.current
        if (document.body.classList.contains('sidebar-collapse')) {
            document.body.classList.remove('sidebar-collapse')
        }
        else {
            document.body.classList.add('sidebar-collapse')
        }
    }


    return (



        <div className="wrapper">

            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a href="#" onClick={toggleSidebar} className="nav-link" data-widget="pushmenu" role="button">
                            <Menu></Menu>
                        </a>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link h5 mb-0">
                            {state && <span>{state.name}</span>}
                        </div>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle
                                tag="span"
                                className="pointer d-flex jc"
                                data-toggle="dropdown"
                                aria-expanded={dropdownOpen}
                            >
                                <User className="nav-user-icon mr-2"></User>
                                <span className="text-old font-weight-bold">{UserStore.props.user.nom}</span>
                                <ChevronDown className="nav-user-icon ml-2"></ChevronDown>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-lg" right>
                                <div className="dropdown-item dropdown-header"><strong>Mon compte</strong></div>
                                <div className="dropdown-divider"></div>
                                <DropdownItem onClick={() => { setIsOpenModalPassword(true) }} className="dropdown-item">
                                    <Lock></Lock> Changer mon mot de passe
                                </DropdownItem>
                                <div className="dropdown-divider"></div>
                                <DropdownItem>
                                    <Link to="/logout" className="dropdown-item p-0">
                                        <LogOut></LogOut> Se déconnecter
                                    </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                </ul>
            </nav>

            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <NavLink to="/" className="brand-link text-center">
                    <Logo className="dematick-orange-white logo-admin"></Logo>
                </NavLink>
                <div className="mt-2 sidebar-header text-center">
                    <div className="avatar-first-letter">
                        <span className="font-weight-bold">{societe.charAt(0)}</span>
                    </div>
                    <div className="mt-2 text-white "><strong>{societe}</strong></div>
                    <small className="mt-2 text-muted ">{roleLabel[role_id]}</small>
                </div>
                <div className="sidebar">
                    <nav className="mt-2">
                        <AppMenu></AppMenu>
                    </nav>
                </div>
            </aside>

            <div className="content-wrapper pt-2">
                <section className="content">
                    <div className="container-fluid">
                        {children}
                    </div>
                </section>
            </div>
            <ModalEditPassword submit={(values,reset,toggle) => UserStore.editPassword(values,reset,toggle)} isOpen={isOpenModalPassword} toggle={() => setIsOpenModalPassword(!isOpenModalPassword)}></ModalEditPassword>
        </div>

    )

}))


export default SidebarLayout