import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Redirect, Route,withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsAuthenticatedAs = observer((props) => {

    const isAuth = UserStore.props.user.isAuthenticated
    const { children } = props

    useEffect(() => {
        UserStore.checkLoggedInAs()
    }, [props.location])

    console.log('Middleware ISAUTH_AS',isAuth)

    return <Route
        render={({ location }) => {
            if (isAuth) {
                return children
            } else {
                
                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { name : 'login', from: location }
                    }}
                />
            }
        }
        }></Route>
})

export default IsAuthenticatedAs