import React, { useState, useEffect } from 'react'
import { createBrowserHistory } from "history";
import {
    BrowserRouter as Router,
    // HashRouter,
    Route,
    Switch
} from "react-router-dom";

import DashboardPage from '../pages/Dashboard/DashboardPage';
import SidebarLayout from '../pages/Layouts/SidebarLayout';
import LoginPage from '../pages/Login/LoginPage';

import EditeurPage from '../pages/Editeur/EditeurPage';

import IsNotAuthenticated from "../middlewares/IsNotAuthenticated";
import IsAuthenticated from "../middlewares/IsAuthenticated";
import IsAuthenticatedAs from "../middlewares/IsAuthenticatedAs";
// import createBrowserHistory  from 'history/createBrowserHistory';
import LogoutPage from "../pages/Logout/LogoutPage";
import ReseauPage from "../pages/Reseau/ReseauPage";
import CommercantPage from "../pages/Commercant/CommercantPage";
import ClientPage from "../pages/Client/ClientPage";
import TicketPage from "../pages/Ticket/TicketPage";
import ModeleTicketPage from "../pages/Ticket/ModeleTicketPage";
import CaissePage from "../pages/Caisse/CaissePage";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import UserStore from '../stores/UserStore';
import SurveyPage from '../pages/Survey/SurveyPage';
import UtilisateurPage from '../pages/Utilisateur/Uitlisateur';


const customHistory = createBrowserHistory();

const AppRoute = (() => {

    const [ready, setReady] = useState(false)

    useEffect(async () => {
        await UserStore.init()
        setReady(true)
    }, [])

    return (
        <>
            {
                ready &&
                <Router history={customHistory}>
                    <Switch>
                        <Route exact path={["/", "/dashboard"]} name="Dashboard">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <DashboardPage></DashboardPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route path="/login">
                            <IsNotAuthenticated>
                                <LoginPage></LoginPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path="/forgot">
                            <IsNotAuthenticated>
                                <ForgotPasswordPage></ForgotPasswordPage>
                            </IsNotAuthenticated>
                        </Route>
                        <Route exact path={["/", "/dashboard"]} name="Dashboard">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <DashboardPage></DashboardPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path={"/logout"} name="Déconnexion">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <LogoutPage></LogoutPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/utilisateurs">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <UtilisateurPage></UtilisateurPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/editeurs">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <EditeurPage></EditeurPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/reseaux">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <ReseauPage></ReseauPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/commercants">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <CommercantPage></CommercantPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/caisses">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <CaissePage></CaissePage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/clients">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <ClientPage></ClientPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/enquetes">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <SurveyPage></SurveyPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/tickets/client">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <TicketPage></TicketPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                        <Route exact path="/tickets/modele">
                            <IsAuthenticated>
                                <SidebarLayout>
                                    <ModeleTicketPage></ModeleTicketPage>
                                </SidebarLayout>
                            </IsAuthenticated>
                        </Route>
                    </Switch>
                </Router>
            }
        </>
    );
})


export default AppRoute;
