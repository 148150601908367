import { observable, toJS } from 'mobx'
import moment from 'moment'
import { Check, CheckCircle, Feather, X, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'
import myYup from '../utils/myYup'
import modalConfirm from '../components/Confirm'
import ReseauStore from './ReseauStore'
import UserStore from './UserStore'
import { ROLE_RESEAU, ROLE_COMMERCANT } from '../utils/constants'


const props = {
    pageReady: false,
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    currentArticles: {},
    data: [],
    steps: [],
    pagination: {},
    isOpenDetailModal: false,
    isOpenActivateModal: false,
    isOpenEditCreateModal: false,
    isOpenPreviewModal: false,
    shops_with_no_modele: [],
    currStep: 0,
    predifinedModeles: [],
    listShop: [],
    currJson: null,
    currContenu: null,
    currentRow: {},
    currentPreview: {},
    listActions: []
}

const ModeleTicketStore = observable({
    /***** Props ******/
    props: props,
    /***** Filter ******/
    getFilteredData(values) {
        // console.log(toJS(values))
        this.props.activeFilters = values
        this.loadData()
    },
    /***** Sort ******/
    toggleSort(column) {
        // console.log(column)
        for (let key in this.props.activeFilters) {

            if (column == key) {

                if (!this.props.activeFilters[key].sort) {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'ASC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'ASC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'DESC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'DESC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = null
                    // break
                }
            }
            else {
                this.props.activeFilters[key].sort = null
            }

        }

        this.loadData()
        console.log(toJS(this.props.filters))
    },
    /***** Toggle Columns ******/
    toggleColumn(column) {
        console.log('toggle')
        this.props.columns.map((item, index) => {
            if (item.key == column.key) {
                this.props.columns[index].isHidden = !this.props.columns[index].isHidden
                console.log(this.props.columns[index].isHidden)

            }
        })
    },
    /***** Pagination change ******/
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.loadData()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.loadData()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.loadData()
                break;

            default:
                break;
        }

    },
    /***** Initialisation ******/
    async init() {

        //On reset le store
        this.reset()

        //Initialisation des listes
        // this.props.listShop = await this.initListShop()

        // Initialisation des columns 
        this.props.columns = [

            {
                label: 'Image modèle',
                key: 'preview',
                field: 'preview',
                type: 'image'
            },
            {
                label: 'Nom modèle',
                key: 'nom',
                field: 'nom'
            },
            {
                label: 'Réseau',
                key: 'reseau',
                field: 'reseau',
                render: (value) => {
                    return typeof value == 'object' ? value?.nom : ''

                }
            },
            {
                label: 'Actif?',
                key: 'commercants',
                field: 'commercants',
                render: (value, row) => {
                    if (row.is_default)
                        return <CheckCircle size={20} className="text-success"></CheckCircle>
                    else if (value.length > 0)
                        return <CheckCircle size={20} className="text-success"></CheckCircle>
                    else
                        return <XCircle size={20} className="text-danger"></XCircle>
                }
            },
            {
                label: 'Actif pour',
                key: 'commercants',
                field: 'commercants',
                render: (value, row) => {
                    return row.is_default ? 'Tous les magasins' : value.map(item => item.nom + ', ')
                }
            },
            {
                key: 'created_at',
                label: 'Créée le',
                field: 'created_at',
                render: (value) => moment(value).format('DD/MM/YYYY')
            },
            {
                key: 'actions',
                label: 'Actions',
                listActions: [
                    // {
                    //     label: 'Activer',
                    //     name: 'activer',
                    //     action: (row) => this.enableModele(row)
                    // },
                    {
                        label: 'Activer/Désactiver ce modèle',
                        name: 'toggle',
                        action: (row) => this.toggleActivateModal(row)
                    },
                    {
                        label: 'Aperçu',
                        name: 'details',
                        action: (row) => this.togglePreviewModal(row)
                    },
                    {
                        label: 'Modifier',
                        name: 'details',
                        action: (row) => this.toggleEditCreateModal(row)
                    },
                    {
                        label: 'Supprimer',
                        name: 'delete',
                        action: (row) => this.deleteModele(row)
                    }
                ]
            }
        ]

        this.props.listActions = [
            {
                label: 'Activer/Désactiver ce modèle',
                name: 'toggle',
                action: (row) => this.toggleActivateModal(row)
            },
            {
                label: 'Aperçu 222',
                name: 'details',
                action: (row) => this.togglePreviewModal(row)
            },
            {
                label: 'Modifier',
                name: 'details',
                action: (row) => this.toggleEditCreateModal(row)
            }
        ]


        // Initialisation des filtres
        this.props.filters = {
            created_at: {
                active: false,
                isSortable: true,
                label: 'Nom modèle',
                type: 'text',
                operator: 'like',
                value: null,
                sort: null
            },
            created_at: {
                active: false,
                isSortable: true,
                label: 'Date création',
                type: 'date',
                operator: 'between',
                value: [null, null],
                sort: 'DESC'
            }
        }

        //Initialisation pagination
        this.props.pagination = {
            index: 1,
            offset: 20,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

        //Inititialisation des étapes de création d'email
        this.props.steps = [
            {
                title: 'Choix modèle',
                hide: ['edit'],

            },
            {
                title: 'Conception',
                hide: [],
                validationSchema: myYup.object().shape({
                    titre: myYup.string().required(),
                    // objet: myYup.string().required(),
                    is_active: myYup.string(),
                    shops: myYup.array().when('is_active', {
                        is: (val) => val == 1,
                        then: myYup.array().when('reseau.default_modele_email_id', {
                            is: (val) => val == 0,
                            then: myYup.array().min(1, 'Vous devez sélectionner au moins un magasin'),
                            otherwise: null
                        })
                        ,
                        otherwise: null,
                    }),
                    contenu: myYup.string().required(),

                })
            }
        ]

        this.props.activeFilters = this.props.filters
        await this.loadData()
        this.props.pageReady = true
    },
    /***** Methods ******/
    async fetchShops(reseau = null) {
        const res = await api.post('/commercant/listShopByReseau', { reseau: reseau })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            this.props.listShop = res.data.data.map((item => ({ id: item.id, label: item.nom })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return []
        }
    },
    async deleteModele(values) {
        LoadingStore.on()

        let next = false


        let text = '<p>Etes-vous sûr(e) de vouloir supprimer le modèle suivant :</p>';
        text += '<p><b>' + values.nom + '</b></p>';

        next = await modalConfirm({
            title: 'Confirmation',
            text: text
        })


        if (next) {
            const res = await api.post('/ticket/deleteModeleEmail', values)
            if (res.data && res.data.success) {
                // this.toggleEditCreateModal()
                modalAlert({
                    title: 'Succes',
                    typeAlert: 'success',
                    text: res.data.msg
                })
                await this.loadData()
            }
            // else if (res.data.errors) {
            //     setErrors(res.data.errors)
            // }
            else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
            }
        }
        LoadingStore.off()
    },
    getEditoroptions() {
        let editorOptions = {
            locale: 'fr-FR',
            appearance: {
                panels: {
                    tools: {
                        dock: 'left'
                    }
                }
            }
        }
        return editorOptions
    },
    getValidationSchema() {
        const steps = this.getWizardSteps()
        console.log(steps, toJS(this.props.currStep))
        return steps[this.props.currStep].validationSchema
    },
    async toggleDetailModal(row) {
        // console.log(row)
        this.props.currentRow = row ? row : {}
        if (row.has_json)
            this.props.currentArticles = await this.fetchArticles(row)
        this.props.isOpenDetailModal = !this.props.isOpenDetailModal
    },
    getWizardSteps() {
        if (this.props.currentRow.json)
            return this.props.steps.filter(item => !item.hide.includes('edit'))
        else
            return this.props.steps
    },
    async toggleEditCreateModal(row) {
        console.log(row)

        this.props.currentRow = row ? row : {}
        if (!row && !this.props.isOpenEditCreateModal) {
            const ress = await this.fetchModeleEmaiTicket()
            this.props.predifinedModeles = ress.data
            if (UserStore.props.user.role_id == ROLE_RESEAU || UserStore.props.user.role_id == ROLE_COMMERCANT) {
                const currReseau = await ReseauStore.fetchCurrReseauParams()
                this.props.currentRow.reseau = currReseau

            }
        }
        else if (row && !this.props.isOpenEditCreateModal) {

            this.props.currentRow.commercants = row.commercants.map(item => item.id)

            const res = await this.fetchModeleEmaiTicketById(row.id)
            console.log(res)
            if (res && res.success) {
                this.props.currentRow.contenu = res.data.contenu
                this.props.currentRow.json = res.data.json
                // this.props.currentRow.contenu = null
                // this.props.currentRow.json = null
                this.props.currentRow.commercants = res.data.commercants.map(item => item.id)
                this.props.currentRow.reseau = res.data.reseau
            }
        }
        this.props.isOpenEditCreateModal = !this.props.isOpenEditCreateModal
        if (!this.props.isOpenEditCreateModal)
            setTimeout(() => this.props.currStep = 0, .5)

    },
    async togglePreviewModal(row) {
        console.log(row)
        this.props.currentPreview = row ? row : {}
        if (row) {
            const res = await this.fetchModeleEmaiTicketById(row.id)
            console.log(res)
            this.props.currentPreview.contenu = res.data.contenu
        }
        this.props.isOpenPreviewModal = !this.props.isOpenPreviewModal


    },
    async toggleActivateModal(row) {
        // console.log(toJS(row))
        this.props.currentRow = row ? row : {}
        if (row) {
            const res = await this.fetchModeleEmaiTicketById(row.id)
            if (res)
                this.props.currentRow.reseau = res.data.reseau
            this.props.currentRow.commercants = row.commercants.map(item => item.id)
        }
        this.props.isOpenActivateModal = !this.props.isOpenActivateModal
    },
    async previewDefaultModele() {
        if (!this.props.isOpenPreviewModal) {
            const res = await this.fetchDefaultModele()
            if (res && res.success)
                this.props.currentPreview.contenu = res.contenu
        }
        this.props.isOpenPreviewModal = !this.props.isOpenPreviewModal

    },
    async enableModele(row) {

        const next = await modalConfirm({
            title: 'activation',
            text: <>
                {
                    row.is_active > 0 &&
                    <>
                        <p>Souhaitez-vous vraiment <b>désactiver</b> ce modèle?</p>
                        <p>Le modèle par défaut sera utilisé</p>
                    </>
                }
                {
                    row.is_active == 0 &&
                    <>
                        <p>Souhaitez-vous vraiment <b>activer</b> ce modèle?</p>
                        <p>Le précédent modèle utilisé sera désactivé</p>
                    </>
                }
            </>
        })

        if (next) {
            LoadingStore.on()
            const res = await api.post('/ticket/enableModeleTicket', { modele_id: row.id, is_active: row.is_active })
            if (res.data && res.data.success) {
                await this.loadData()
                LoadingStore.off()
                modalAlert({
                    title: 'Succès',
                    typeAlert: 'success',
                    text: res.data.msg
                })
            }
            else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
                LoadingStore.off()
            }
        }
    },
    async nextStep(data = {}, setFieldValue) {

        if (this.props.currStep == 0) {
            const { modele_id } = data

            if (modele_id) {
                const res = await this.fetchModeleEmaiTicketById(modele_id)
                // console.log('test', res.data)
                setFieldValue('contenu', res.data.contenu)
                setFieldValue('json', res.data.json)

            } else {
                setFieldValue('contenu', '')
                setFieldValue('json', null)
            }
        }

        // if (this.props.currStep == 1) {
        //     console.log('In enxt',setDesign)

        // }
        this.props.currStep++
    },
    async prevStep(data = {}) {
        if (typeof this.props.steps[this.props.currStep].onNextStep == 'function')
            this.props.steps[this.props.currStep].onPrevStep(data)
        this.props.currStep--;
    },
    async loadData() {
        LoadingStore.on()
        const res = await api.post('/ticket/modeleTicketList', { pagination: this.props.pagination, filters: this.props.activeFilters })
        if (res.data && res.data.success) {
            this.props.data = res.data.data
            this.props.shops_with_no_modele = res.data.shops_with_no_modele
            this.props.pagination.total = res.data.total
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    async fetchModeleEmaiTicket(row) {
        LoadingStore.on()
        const res = await api.post('/ticket/getModeleTicket')
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async fetchModeleEmaiTicketById(modele_id) {
        LoadingStore.on()
        const res = await api.post('/ticket/getModeleTicketById', { modele_id: modele_id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async fetchDefaultModele() {
        LoadingStore.on()
        const res = await api.post('/ticket/fetchDefaultModele')
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async checkIfAlreadySet(values) {
        const res = await api.post('/ticket/checkIfAlreadySet', values)
        if (res.data && res.data.success) {
            return res.data
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return null
        }
    },
    async submitModeleTicket(values, setErrors) {
        LoadingStore.on()

        let next = true

        // const already = await this.checkIfAlreadySet(values)

        // if (already.alert) {

        //     let text = '<p>En sauvgardant  cette enquête, vous remplacerez les enquêtes actives des magasins suivants :</p><p><ul>';
        //     already.commercantsToAlert.map(item => text += '<li><b>' + item.nom + '</b></li>');
        //     text += '</ul></p><p>Etes-vous sûr(e) de vouloir continuer ? </p>'

        //     next = await modalConfirm({
        //         title: 'enregistrement',
        //         text: text
        //     })

        // }
        if (next) {
            const res = await api.post('/ticket/modeleCreate', values)
            if (res.data && res.data.success) {
                console.log(res.data)
                this.toggleEditCreateModal()
                modalAlert({
                    title: 'Succes',
                    typeAlert: 'success',
                    text: res.data.msg
                })
                await this.loadData()
            }
            else if (res.data.errors) {
                setErrors(res.data.errors)
            }
            else if (typeof res.data.success == 'boolean') {
                modalAlert({
                    title: 'Erreur',
                    typeAlert: 'danger',
                    text: "Une erreur est survenue"
                })
            }
        }
        LoadingStore.off()
    },
    async toggleModeleTicket(values, setErrors) {
        LoadingStore.on()
        const res = await api.post('/ticket/toggleModeleTicket', values)
        if (res.data && res.data.success) {
            console.log(res.data)
            this.toggleActivateModal()
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
            await this.loadData()
        }
        else if (res.data.errors) {
            setErrors(res.data.errors)
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        // console.log('RESET STORE')
        this.props = props
    }
})


export default ModeleTicketStore