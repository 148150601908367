import { observable, toJS } from 'mobx'
import moment from 'moment'
import { CheckCircle, XCircle } from 'react-feather'
import api from '../api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    currentTicket: {},
    data: [],
    pagination: {},
    isOpenDetailModal: false,
}

const TicketStore = observable({
    /***** Props ******/
    props: props,
    /***** Filter ******/
    getFilteredData(values) {
        // console.log(toJS(values))
        this.props.activeFilters = values
        this.loadData()
    },
    /***** Sort ******/
    toggleSort(column) {
        // console.log(column)
        for (let key in this.props.activeFilters) {

            if (column == key) {

                if (!this.props.activeFilters[key].sort) {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'ASC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'ASC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = 'DESC'
                    // break
                }
                else if (this.props.activeFilters[key].sort == 'DESC') {
                    console.log(toJS(this.props.activeFilters[key]))
                    this.props.activeFilters[key].sort = null
                    // break
                }
            }
            else {
                this.props.activeFilters[key].sort = null
            }

        }

        this.loadData()
        console.log(toJS(this.props.filters))
    },
    /***** Toggle Columns ******/
    toggleColumn(column) {
        console.log('toggle')
        this.props.columns.map((item, index) => {
            if (item.key == column.key) {
                this.props.columns[index].isHidden = !this.props.columns[index].isHidden
                console.log(this.props.columns[index].isHidden)

            }
        })
    },
    /***** Pagination change ******/
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.loadData()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.loadData()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.loadData()
                break;

            default:
                break;
        }

    },
    /***** Initialisation ******/
    async init() {

        //Recupération de la liste des commercant
        const listShop = await this.initListShop()

        // Initialisation des columns 
        this.props.columns = [
            {
                key: '',
                label: 'Type ticket',
                field: '',
                render: (value,row) => {
                    return row['has_text'] ? 'Texte' : row['has_image'] ? 'Image' : row['has_json'] ? 'Numérique' : '--'
                }
            },
            {
                label: 'Client associé',
                key: 'client',
                field: 'client',
                render: (value) => {
                    return value ? <span className="font-weight-bold">{value.nom ? value.nom : '-' + ' ' + value.prenom ? value.prenom : '-'}</span> : '--'
                }
            },
            {
                label: 'Magasin associé',
                key: 'commercant',
                field: 'commercant',
                render: (value) => {
                    return <span className="font-weight-bold">{value.nom}</span>
                }
            },
            {
                key: 'total_ttc_txt',
                label: 'Montant TTC',
                field: 'total_ttc_txt',
                render: (value) => value ? value / 100 + ' €' : '--'
            },
            {
                key: 'created_at',
                label: 'Créée le',
                field: 'created_at',
                render: (value) => moment(value).format('DD/MM/YYYY')
            },
            {
                key: 'actions',
                label: 'Actions',
                listActions: [
                    {
                        label: 'Voir détails',
                        name: 'details',
                        action: (row) => this.toggleDetailModal(row)
                    }
                ]
            }
        ]

        // Initialisation des filtres
        this.props.filters = {
            no_ticket: {
                active: false,
                isSortable: true,
                label: 'N° ticket',
                type: 'text',
                operator: '%%',
                value: null,
                sort: null
            },
            commercant_id: {
                label: 'Commercant',
                isSortable: true,
                list : listShop,
                active: false,
                type: 'checkboxes',
                operator: 'in',
                value: [],
                sort: null
            },
            created_at : {
                active: true,
                isSortable: true,
                label: 'Date création',
                type: 'date',
                operator: 'between',
                value: [null,null],
                sort: 'DESC'
            }
        }

        //Initialisation pagination
        this.props.pagination = {
            index: 1,
            offset: 20,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

        this.props.activeFilters = this.props.filters
        await this.loadData()
        this.props.pageReady = true
    },
    /***** Methods ******/
    getEditoroptions() {
        let editorOptions = {
            locale: 'fr-FR',
            appearance: {
                panels: {
                    tools: {
                        dock: 'left'
                    }
                }
            },
            mergeTags: {
                civilite: {
                    name: "Mme/Mlle/Mr",
                    value: " ::civilite:: "
                },
                nom: {
                    name: "Nom du client",
                    value: " ::nom:: "
                },
                prenom: {
                    name: "Prénom du client",
                    value: " ::prenom:: "
                }
            },
            translations: {
                'fr-FR': {
                    "labels.merge_tags": "Informations du client",
                    "editor.placeholder.text": "Pas de contenu ici. Faites glisser le contenu de gauche.",
                }
            }
        }
        // if (UserStore.isReseau())
        //     editorOptions['mergeTags']['nomResCom'] = {
        //         name: "Nom du réseau :" + UserStore.props.user.nom_reseau,
        //         value: UserStore.props.user.nom_reseau
        //     }
        // else if (UserStore.isCommercant())
        //     editorOptions['mergeTags']['nomResCom'] = {
        //         name: "Nom du réseau :" + UserStore.props.user.nom_commercant,
        //         value: UserStore.props.user.nom_commercant
        //     }

        // if (UserStore.isCampagne()) {
        //     delete editorOptions['mergeTags']['solde_points']
        //     delete editorOptions['mergeTags']['solde_euros']
        //     delete editorOptions['mergeTags']['exp_points']
        //     delete editorOptions['mergeTags']['exp_euros']
        // }

        return editorOptions
    },
    async initListShop() {
        const res = await api.post('/commercant/listShop')
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.data.map((item => ({ id: item.id, label: item.nom })))
        }
        else if(typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return []
        }
    },
    async toggleDetailModal(row) {
        // console.log(row)
        this.props.currentRow = row ? row : {}
        if (row)
            this.props.currentTicket = await this.fetchTicketById(row)
        this.props.isOpenDetailModal = !this.props.isOpenDetailModal
    },
    async loadData() {
        LoadingStore.on()
        const res = await api.post('/ticket/list', { pagination: this.props.pagination, filters: this.props.activeFilters })
        if (res.data && res.data.success) {
            this.props.data = res.data.data
            this.props.pagination.total = res.data.total
        }
        else if(typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    async fetchTicketById(row) {
        LoadingStore.on()
        const res = await api.post('/ticket/fetchTicketById', { id: row.id })
        if (res.data && res.data.success) {
            LoadingStore.off()
            return res.data
        }
        else if(typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            LoadingStore.off()
            return []
        }
    },
    async submitTicket(values,setErrors) {
        LoadingStore.on()
        const res = await api.post('/commercant/store', values)
        if (res.data && res.data.success) {
            this.toggleDetailModal()
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
            await this.loadData()
        }
        else if (res.data.errors) {
            setErrors(res.data.errors)
        }
        else if(typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
        }
        LoadingStore.off()
    },
    reset() {
        console.log('RESET STORE')
        this.props = props
    }
})


export default TicketStore