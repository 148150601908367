import React, { useState } from 'react'
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { PopoverBody, Popover, Collapse } from 'reactstrap';
import Input from './Input';
import { useFormik } from 'formik';
import { toJS } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';


export class Portal extends React.Component {
    render() {
        const { children, target = document.body } = this.props;

        return createPortal(children, target);
    }
}

export const Filter = observer((props) => {
    const {
        filters,
        applyFilter
    } = props

    const { errors, setFieldValue, handleSubmit, values, handleReset } = useFormik({
        // enableReinitialize: true,
        initialValues: filters,
        onSubmit: values => submitFilter(values),
        onReset: values => resetFilter(values),
        // validationSchema: myYup.object().shape({
        //     nom: myYup.string().ensure().required(),
        // }),
        validateOnBlur: false,
        validateOnChange: false
    })

    const submitFilter = (values) => {
        console.log(values)
        toggle()
        applyFilter(values)
    }

    const resetFilter = (values) => {
        // handleReset()
        // resetForm()
        // console.log(toJS(values))
        console.log('RESET FILTER')
        toggle()
        applyFilter(values)
    }

    const getFieldType = (type) => {
        let typeField = null;
        // console.log(type)
        switch (type) {
            case 'text':
                typeField = 'text'
                break;
            case 'list':
                typeField = 'reactSelect'
                break;
            case 'dropdown_list':
                typeField = 'mySelect'
                break;
            case 'numeric':
                typeField = 'number'
                break;
            case 'date':
                typeField = 'date'
                break;
            case 'checkboxes':
                typeField = 'checkboxes'
                break;

            default:
                break;
        }

        return typeField
    }

    const operatorList = (type) => {
        let liste = [];
        switch (type) {
            case 'text':
                liste = [
                    { value: '%%', label: 'Contient' },
                    { value: '=', label: 'Egale à' },
                ]
                break;
            case 'dropdown_list':
                liste = [
                    { value: '=', label: 'Egale à' },
                ]
                break;
            case 'numeric':
                liste = [
                    { value: '=', label: 'Egale à' },
                    { value: '>', label: 'Strictement supérieur à' },
                    { value: '<', label: 'Strictement inférieur à' },
                    { value: '>=', label: 'Supérieur ou égale à' },
                    { value: '<=', label: 'Inférieur ou égale à' },
                    { value: '<>', label: 'Différent de' },
                ]
                break;
            case 'date':
                liste = [
                    { value: '=', label: 'Egale à' },
                    { value: '[]', label: 'Intervalle' },
                    { value: '>', label: 'Strictement supérieur à' },
                    { value: '<', label: 'Strictement inférieur à' },
                    { value: '>=', label: 'Supérieur ou égale à' },
                    { value: '<=', label: 'Inférieur ou égale à' },
                    { value: '<>', label: 'Différent de' },
                ]
                break;

            default:
                break;
        }

        return liste
    }
    // console.log(toJS(values))
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => { setPopoverOpen(!popoverOpen) };

    return (
        <>
            {/* {
                popoverOpen &&
                <Portal container={document.body}>
                    <div class="modal-backdrop fade show"></div>
                </Portal>
            } */}
            <div className="ml-2">
                <button id="btn-filter" className={'btn btn-success btn-sm'}>
                    <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                    Filtre</button>
                <Popover popperClassName="popover-filter" placement="bottom" trigger="legacy"
                    target="btn-filter" isOpen={popoverOpen} toggle={() => toggle()}>
                    <PopoverBody className="p-0">
                        <div className="d-flex border-bottom filter-header rounded py-1">
                            <div className="col">
                                <button onClick={handleReset} className="btn btn-xs btn-secondary">Effacer</button>
                            </div>
                            <div className="col text-center font-weight-bold">
                                Filtres
                            </div>
                            <div className="col text-right">
                                <button onClick={handleSubmit} className="btn btn-xs btn-primary">Terminé</button>
                            </div>
                        </div>
                        {
                            Object.keys(filters).map((key) =>
                                <>
                                    {
                                        !filters[key].isNotFilterable &&

                                        <div className="row mx-0 mt-1  border-bottom">
                                            <div className="col-12 mb-1">
                                                <Input
                                                    name={key + '.active'}
                                                    type={'checkbox'}
                                                    label={filters[key].label}
                                                    value={values[key]?.active}
                                                    className="form-control form-control-sm pointer"
                                                    onChange={setFieldValue}
                                                    error={errors[key]?.active}
                                                />

                                            </div>
                                            <Collapse isOpen={values[key].active} className="col-12 filter-collapse">
                                                <div className="row py-2">
                                                    {
                                                        (getFieldType(filters[key].type) == 'text'
                                                            || getFieldType(filters[key].type) == 'dropdown_list')
                                                        &&
                                                        <>
                                                            <div className="col-4">
                                                                <Input
                                                                    name={key + '.operator'}
                                                                    type={'select'}
                                                                    list={operatorList(filters[key].type)}
                                                                    value={values[key]?.operator}
                                                                    className="form-control form-control-sm"
                                                                    onChange={setFieldValue}
                                                                    error={errors[key]?.operator}
                                                                />
                                                            </div>
                                                            <div className="col-8 is-sm">
                                                                <Input
                                                                    name={key + '.value'}
                                                                    fetch={filters[key].fetch}
                                                                    type={getFieldType(filters[key].type)}
                                                                    value={values[key]?.value}
                                                                    className="form-control form-control-sm"
                                                                    onChange={setFieldValue}
                                                                    error={errors[key]?.value}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        getFieldType(filters[key].type) == 'date'
                                                        &&
                                                        <>
                                                            <div className="col-1 px-1">
                                                                Du
                                                            </div>
                                                            <div className="col-5 px-1 is-sm">
                                                                <Input
                                                                    name={key + '.value[0]'}
                                                                    type={getFieldType(filters[key].type)}
                                                                    value={values[key]?.value[0]}
                                                                    className="form-control form-control-sm"
                                                                    onChange={setFieldValue}
                                                                    error={errors[key]?.value[0]}
                                                                />
                                                            </div>
                                                            <div className="col-1 px-1">
                                                                au
                                                            </div>
                                                            <div className="col-5 px-1 is-sm">
                                                                <Input
                                                                    name={key + '.value[1]'}
                                                                    type={getFieldType(filters[key].type)}
                                                                    value={values[key]?.value[1]}
                                                                    className="form-control form-control-sm"
                                                                    onChange={setFieldValue}
                                                                    error={errors[key]?.value[1]}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        getFieldType(filters[key].type) == 'checkboxes'
                                                        &&
                                                        <>
                                                        {console.log(key)}
                                                            <div className="col row px-1 mx-0">
                                                                <Input
                                                                    name={key + '.value'}
                                                                    list={filters[key].list}
                                                                    type={getFieldType(filters[key].type)}
                                                                    value={values[key]?.value}
                                                                    className="col-md-6"
                                                                    onChange={setFieldValue}
                                                                    error={errors[key]?.value}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>
                                    }
                                </>
                            )
                        }
                    </PopoverBody>
                </Popover>
            </div>
        </>
    )
})